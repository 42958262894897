import React, { useState, useEffect } from "react";
import { Form, Modal, Row, Col, Input, InputNumber, Select } from "antd";
import ButtonSave from "../../../../Components/ButtonSave";
const { Option } = Select;
const ModalNewItem = ({
  formModalNewItem,
  isShowModalNewItem,
  dismissModalNewItem,
  onSubmitModalNewItem,
}) => {
  return (
    <Modal
      title={
        <label
          className="font-common"
          style={{ fontSize: "30px", paddingTop: "15px" }}
        >
          {"เพิ่มรายการ"}
        </label>
      }
      visible={isShowModalNewItem}
      onCancel={dismissModalNewItem}
      footer={null}
    >
      <Form onFinish={onSubmitModalNewItem} form={formModalNewItem}>
        <Row>
          <Col xl={6} style={{ paddingTop: "3px" }}>
            <span className="font-common size-common">ชื่อรายการ</span>
          </Col>
          <Col xl={12}>
            <Form.Item
              name={"name"}
              rules={[
                {
                  required: true,
                  message: "โปรดกรุณากรอกข้อมูล",
                },
              ]}
            >
              <Input placeholder={"ชื่อรายการ"}></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <span className="font-common size-common">จำนวน</span>
          </Col>
          <Col xl={12}>
            <Form.Item name={"amount"}>
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%", borderRadius: "25px" }}
                placeholder={"จำนวน"}
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <span className="font-common size-common">ราคาต่อหน่วย</span>
          </Col>
          <Col xl={12}>
            <Form.Item name={"price"}>
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%", borderRadius: "25px" }}
                placeholder={"ราคาต่อหน่วย"}
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <span className="font-common size-common">หน่วย</span>
          </Col>
          <Col xl={12}>
            <Form.Item
              name={"unit"}
              rules={[
                {
                  required: true,
                  message: "โปรดกรุณากรอกข้อมูล",
                },
              ]}
            >
              <Input placeholder={"หน่วย"}></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <span className="font-common size-common">ค่าแรง</span>
          </Col>
          <Col xl={12}>
            <Form.Item name={"wage"}>
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%", borderRadius: "25px" }}
                placeholder={"ค่าแรง"}
              ></InputNumber>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <span className="font-common size-common">Factor F</span>
          </Col>
          <Col xl={12}>
            <Form.Item
              name={"factorFTypeId"}
              rules={[
                {
                  required: true,
                  message: "โปรดกรุณากรอกข้อมูล",
                },
              ]}
            >
              <Select style={{ width: 200 }}>
                <Option value="1">ชลประทาน</Option>
                <Option value="2">ทาง</Option>
                <Option value="3">สะพาน</Option>
                <Option value="4">vat</Option>
                <Option value="5">อาคาร</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ paddingTop: "5px", backgroundColor: "" }}>
          <ButtonSave label="บันทึก" width="100%"></ButtonSave>
        </Form.Item>
        <Form.Item name={"key"}>
          <Input hidden placeholder={"key"}></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalNewItem;
