import React, { Component } from "react";
import { Route } from "react-router-dom";
import Login from "../Pages/Login";
// import Logout from '../Pages/Logout';
import Home from "../Pages/Home";

import AppConfig from "../AppConfig";

export default class GeneralRoutes extends Component {
  render() {
    return (
      <span>
        <Route exact path={AppConfig.appPath + "/"} component={Login} />
        {/* <Route exact path={AppConfig.appPath + "/logout"} component={Logout} /> */}
        <Route exact path={AppConfig.appPath + "/home"} component={Home} />
      </span>
    );
  }
}
