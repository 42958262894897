import React from "react";
import AppConfig from "../AppConfig";
import { Col, Row, Image } from "antd";
import { LogoMain } from "../Constants/Images";
import NavbarPageLabel from "./NavbarPageLabel";
import { NavDropdown } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
const NavBar2 = ({ labelEnglish, labelThai }) => {
  const bgColor = "linear-gradient(#818181, #b8b8b8)";
  return (
    <Row>
      <Col
        xs={12}
        xl={3}
        style={{ background: "linear-gradient(#ff6e00, #c02202)" }}
      ></Col>
      <Col xs={12} xl={1} style={{ background: bgColor }}>
        <a href={AppConfig.appPath + "/home"}>
          <Image
            width={70}
            src={LogoMain}
            preview={false}
            style={{
              // position: "absolute",
              // position: "relative",
              // transform: "translate(220%, 40%)",
              zIndex: 10,
              marginTop: "25px",
              marginLeft: "-35px",
            }}
            // translate(180%, 40%)
          ></Image>
        </a>
      </Col>
      <Col xs={24} xl={14} style={{ background: bgColor }}>
        <h1
          className="font-common"
          style={{
            paddingTop: "45px",
            fontWeight: "bold",
            paddingLeft: "10px",
          }}
        >
          โปรแกรมประมาณราคาภาครัฐ กองมาตรฐานราคากลาง V.
          {AppConfig.appVersion}
        </h1>
      </Col>
      <Col xs={24} xl={6} style={{ background: bgColor }}>
        <Row>
          <Col xs={1} xl={17}></Col>
          <Col xs={3} xl={6} style={{ background: "" }}>
            <NavDropdown
              title={
                <label
                  className="font-common"
                  style={{
                    fontWeight: "bold",
                    fontSize: "25px",
                    color: "black",
                  }}
                >
                  ตั้งค่า
                </label>
                // <SettingOutlined style={{ fontSize: "56px" }} />
              }
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href={AppConfig.appPath + "/user/info"}>
                <label
                  className="font-common"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  แก้ไขข้อมูลส่วนตัว
                </label>
              </NavDropdown.Item>
              <NavDropdown.Item href={AppConfig.rootPath}>
                <label
                  className="font-common"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  ออกจากระบบ
                </label>
              </NavDropdown.Item>
            </NavDropdown>
          </Col>
        </Row>
        <div
          style={{
            width: "350px",
            marginBottom: "10px",
          }}
        >
          <NavbarPageLabel labelThai={labelThai} labelEnglish={labelEnglish} />
        </div>
      </Col>
    </Row>
  );
};

export default NavBar2;
