import React from "react";
import { Form, Row, Col, InputNumber, Divider } from "antd";

const InputForm = ({ title, conceteName, woodName }) => {
  return (
    <div style={{ marginTop: "-35px", backgroundColor: "white" }}>
      <Col xl={18} xs={24}>
        <Divider
          orientation="left"
          className="font-common"
          style={{ fontSize: "20px", fontStyle: "bold" }}
        >
          {title}
        </Divider>
      </Col>
      <Row>
        <Col xl={8} xs={20}>
          <Form.Item
            name={conceteName}
            label={
              <p
                style={{
                  fontSize: "20px",
                  marginTop: "20px",
                  fontStyle: "bold",
                }}
              >
                คอนกรีตโครงสร้างทั้งหมด
              </p>
            }
          >
            <InputNumber
              placeholder="คอนกรีตโครงสร้างทั้งหมด"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{
                width: "100%",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
              }}
            />
          </Form.Item>
        </Col>
        <Col xl={1} xs={1}>
          <div
            className="ant-input-group-addon font-common size-common"
            style={{
              paddingTop: "2px",
              verticalAlign: "middle",
              display: "inline-table",
              lineHeight: "24px",
              height: "32px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            {"ลบ.ม."}
          </div>
        </Col>
        <Col xl={1} xs={0}></Col>
        <Col>
          <Form.Item
            name={woodName}
            label={
              <p
                style={{
                  fontSize: "20px",
                  marginTop: "20px",
                  fontStyle: "bold",
                }}
              >
                ไม้แบบทั้งหมด
              </p>
            }
          >
            <InputNumber
              placeholder="ไม้แบบทั้งหมด"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{
                width: "100%",
                borderTopLeftRadius: "25px",
                borderBottomLeftRadius: "25px",
              }}
            />
          </Form.Item>
        </Col>
        <Col xl={1} xs={1}>
          <div
            className="ant-input-group-addon font-common size-common"
            style={{
              paddingTop: "2px",
              verticalAlign: "middle",
              display: "inline-table",
              lineHeight: "24px",
              height: "32px",
              borderTopRightRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            {"ตร.ม."}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InputForm;
