import React, { Component } from "react";
// import { toast } from 'react-toastify';
// import AuthHelper from '../../Helper/AuthHelper';
import { BackgroundLogin } from "../../Constants/Images";
import LoginForm from "../../Components/LoginForm";
import SecureKey from "../../https/SecureKey";
import Storage from "../../https/Storage";
export default class Login extends Component {
  componentDidMount = () => {
    // if (AuthHelper.isLogin()) {
    // 	this.props.history.replace(AppConfig.appPath + '/dashboard');
    // }
    Storage.clear(SecureKey.accessToken);
    Storage.clear(SecureKey.refreshToken);
    window.sessionStorage.clear();
  };

  render() {
    return (
      <div
        className="background-login"
        style={{
          backgroundImage: `url("${BackgroundLogin}")`,
        }}
      >
        <div
          className="center"
          style={{
            height: "450px",
            width: "60%",
            backgroundColor: "#6c6c6c",
            borderRadius: "50px",
            opacity: "0.5",
            borderStyle: "solid",
            borderColor: "white",
            borderWidth: "4px",
          }}
        ></div>
        <LoginForm router={this.props.history} />
      </div>
    );
  }
}
