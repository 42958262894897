import React from "react";

const LabelRound = ({
  label,
  borderColor,
  background,
  fontColor,
  fontSize,
  width,
  height,
  borderWidth,
}) => {
  return (
    <label
      className="font-common"
      style={{
        background,
        borderRadius: "50px",
        width: width,
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        fontSize,
        color: fontColor,
        borderColor,
        borderWidth,
        borderStyle: borderWidth ? "solid" : null,
      }}
    >
      {label}
    </label>
  );
};

export default LabelRound;
