import React from "react";
import { toast } from "react-toastify";
import "antd/dist/antd.css";
import { Table, Row, Col } from "antd";
// import HTTP from "../../../../https/HTTP";
// import HTTPUtils from "../../../../https/HTTPUtils";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../../Redux/LoadingReducer";
import ButtonSave from "../../../../Components/ButtonSave";
import DownloadHelper from "../../../../Helpers/DownloadHelper";
import AppConfig from "../../../../AppConfig";
import {
  columnPR6Dam,
  columnPR6Building,
} from "../../../../Constants/ColumnFormat";

const TablePR6 = ({ projectId, columnFormat, data }) => {
  const dispatch = useDispatch();

  const downloadSummary = async () => {
    dispatch(showLoading(true));
    let url = "";
    if (columnFormat == columnPR6Dam) {
      url = `${AppConfig.apiUrl}projectItem/summary/pr6/project/${projectId}/dam/download`;
    } else {
      url = `${AppConfig.apiUrl}projectItem/summary/pr6/project/${projectId}/building/download`;
    }

    await downloadAPI(url);
    dispatch(showLoading(false));
  };

  const downloadAPI = async (url) => {
    const response = await fetch(url, {
      method: "GET",
    });

    if (response.status != 200) {
      toast.error("download ล้มเหลว");
      return;
    }
    response
      .blob()
      .then((blob) => DownloadHelper.downloadBlob(blob, "reportPR6.xlsx"));
  };

  return (
    <Table
      pagination={{
        defaultPageSize: 5000,
      }}
      columns={columnFormat}
      dataSource={data}
      bordered
      title={() => (
        <div>
          <Row>
            <Col xl={4}>
              <ButtonSave
                label={"Download"}
                onClick={() => {
                  downloadSummary();
                }}
                width={"150px"}
              ></ButtonSave>
            </Col>
          </Row>
        </div>
      )}
    />
  );
};

export default TablePR6;
