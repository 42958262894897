// import * as SecureStore from 'expo-secure-store';
// import NetInfo from '@react-native-community/netinfo';

// import SecureKey from '../constants/SecureKey';
import SecureKey from "./SecureKey";
import AppConfig from "../AppConfig";
import HTTPUtils from "./HTTPUtils";

const HTTP = (method, service, urlParams, body) => {
  const controller = new AbortController();
  // const signal = controller.signal;
  return {
    abort: () => {
      controller.abort();
    },
    execute: async () => {
      return execute(method, service, urlParams, body, 0);
    },
  };
};

export default HTTP;

const execute = async (method, service, urlParams, body, retryCount) => {
  const startTime = new Date();
  var data = null;
  var status = null;
  if (!urlParams) {
    urlParams = {};
  }

  if (!retryCount) {
    retryCount = 0;
  }

  if (true) {
    try {
      // const netInfo = await NetInfo.fetch();
      // if (netInfo.isConnected) {
      // const timeoutRef = setTimeout(() => {
      //   console.log("timeout");
      //   // fetchController.abort();
      //   // }, AppConfig.apiTimeoutInSeconds * 1000);
      // }, 10 * 1000);
      const queryString = HTTPUtils.buildQueryString(urlParams);
      const requestHeaders = await HTTPUtils.getHttpHeader(
        SecureKey.accessToken
      );
      const apiUrl = AppConfig.apiUrl;
      const url = apiUrl + service + queryString;
      // if (!isFormData) {
      // 	body = JSON.stringify({
      // 		channelName: AppConfig.apiChannelName,
      // 		shoppingChannel: AppConfig.apiChannelName,
      // 		mPayToken,
      // 		ingressLang,
      // 		...body,
      // 	});
      // }

      // console.log(url, method.toUpperCase());
      // console.log(url, body);
      const response = await fetch(url, {
        method: method.toUpperCase(),
        headers: requestHeaders,
        // body: JSON.stringify(body),
        body: body ? JSON.stringify(body) : null,
      });

      // clearTimeout(timeoutRef);
      status = response.status;
      try {
        data = await response.json();
        // console.log(body, data);
      } catch (ex) {}
    } catch (error) {
      console.log(`error in API ${method} : ${service}`);
      // console.log(error);
    }
  }

  const responsedTime = new Date();
  return HTTPUtils.handleAPIResponse(
    execute,
    { status, data, responsedTime },
    { method, service, urlParams, body, startTime },
    retryCount
    // fetchController
  );
};

// const getCurrentLanguage = async () => {
// 	let userLanguage = await SecureStore.getItemAsync(SecureKey.userLanguage);
// 	return userLanguage
// 		? userLanguage.toUpperCase()
// 		: AppConfig.defaultLanguage;
// };

// const getMPayToken = async () => {
// 	const mPayToken = await SecureStore.getItemAsync(SecureKey.mpayToken);
// 	return mPayToken;
// };
