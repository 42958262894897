import { createSlice } from "@reduxjs/toolkit";

export const UserReducer = createSlice({
  name: "user",
  initialState: {
    firstName: null,
    lastName: null,
  },
  reducers: {
    setNavBarName: (state, action) => {
      console.log(action.payload);
      state.firstName = action.payload.firstName;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNavBarName } = UserReducer.actions;

export default UserReducer.reducer;
