import React from "react";
import { toast } from "react-toastify";
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col } from "antd";
// import TableProjectMaterial from "../../../Components/TableProjectMaterial";
import SearchBar from "../../../Components/SearchBar";
import { Background } from "../../../Constants/Images";
import MenuStep from "../../../Components/MenuStep";
import ButtonSave from "../../../Components/ButtonSave";
import AppConfig from "../../../AppConfig";
import DownloadHelper from "../../../Helpers/DownloadHelper";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../Redux/LoadingReducer";
const Material = (props) => {
  const dispatch = useDispatch();
  const onClickDownloadButton = async () => {
    dispatch(showLoading(true));
    const projectId = props.match.params.projectId;
    downloadAPI(projectId);
  };

  const downloadAPI = async (projectId) => {
    const response = await fetch(
      `${AppConfig.apiUrl}Material/project/${projectId}/download`,
      {
        method: "GET",
      }
    );

    if (response.status != 200) {
      toast.error("download ล้มเหลว");
      return;
    }
    response.blob().then((blob) => {
      DownloadHelper.downloadBlob(blob, "material.xlsm");
      dispatch(showLoading(false));
    });
  };
  return (
    <div>
      <NavBar2
        labelEnglish={"MATERIAL PRICE"}
        labelThai={"ราคาวัสดุ"}
      ></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep
                selectedIndex={1}
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={20}
              style={{ paddingLeft: "20px", paddingTop: "20px" }}
            >
              {/* <Row>
                <Col xl={24}>
                  <Row style={{ marginTop: "20px" }}>
                    <Col xl={4}></Col>
                    <Col xl={16}>
                      <TableProjectMaterial />
                    </Col>
                    <Col xl={4}></Col>
                  </Row>
                  <div style={{ marginTop: "10px" }}>
                    <SearchBar></SearchBar>
                  </div>
                </Col>
              </Row> */}
              <Row>
                <Col xl={4}>
                  <ButtonSave
                    width={"300px"}
                    label="Download ไฟล์ฐานข้อมูล"
                    onClick={onClickDownloadButton}
                  ></ButtonSave>
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout.Content>
        {/* <Layout.Footer style={{ textAlign: "center" }}>
            - ระบบประมาณราคาภาครัฐ by CCW -
          </Layout.Footer> */}
      </Layout>
    </div>
  );
};

export default Material;
