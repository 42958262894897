import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Table, Checkbox } from "antd";
const Component = ({ projectId, canSelect, datasource, setDataTable }) => {

  const columns = [];
  function toggle(e, id) {
    const { checked } = e.target;
    const updating = datasource.find(x => x.id === id);
    for (const sw of updating.subWorkGroupPermissions) {
      sw.selected = checked;
    }
    updating.selected = checked;
    setDataTable((prev) => prev.map(prevData => ({...prevData.id === id ? updating : prevData })));
  }

  function toggleWorkGroup(e, userId, subWorkGroupId) {
    console.log('userId', userId);
    console.log('workgroup', subWorkGroupId);
    const { checked } = e.target;
    const updating = datasource.find(x => x.id === userId);
    let haveAnyChecked = false;
    for (const sw of updating.subWorkGroupPermissions) {
      sw.selected = sw.id === subWorkGroupId ? checked : sw.selected;
      if (sw.selected) {
        haveAnyChecked = true;
      }
    }

    setDataTable((prev) => prev.map(d => ({
      ...d,
      selected: d.id === userId ? haveAnyChecked: d.selected,
      subWorkGroupPermissions: d.id === userId ? updating.subWorkGroupPermissions : d.subWorkGroupPermissions,
    })));
  }

  if (canSelect) {
    columns.push({
      title: "สิทธิ์โครงการ",
      dataIndex: "id",
      key: () => Math.random.toString(),
      width: 90,
      align: 'center',
      render: (text, record) => <Checkbox disabled={record.disabled} checked={record.selected} onChange={ (e) => toggle(e, record.id) } />
    });
  }

  function sortAlphabet(a,b, col) {
    if (a[col] < b[col]) {
      return -1;
    } else if (a[col] > b[col]) {
      return 1;
    }

    return 0;
  }

  columns.push(
    {
      title: "ชื่อ",
      dataIndex: "firstName",
      key: "firstName",
      width: 100,
      sorter: (a, b) => sortAlphabet(a, b, 'firstName'),
    },
    {
      title: "นามสกุล",
      dataIndex: "lastName",
      key: "lastName",
      width: 100,
      sorter: (a, b) => sortAlphabet(a, b, 'lastName'),
    },
    {
      title: "แผนก",
      dataIndex: "department",
      key: "department",
      width: 100,
      sorter: (a, b) => sortAlphabet(a, b, 'department'),
    },
    {
      title: "ตำแหน่ง",
      dataIndex: "occupation",
      key: "occupation",
      width: 100,
      sorter: (a, b) => sortAlphabet(a, b, 'occupation'),
    },
    {
      title: "อีเมล",
      dataIndex: "email",
      key: "email",
      width: 100,
      sorter: (a, b) => sortAlphabet(a, b, 'email'),
    },
  );

  const expandedRowRender = (rowData) => {
    const columns = [
      {
        title: '',
        dataIndex: 'operation',
        key: 'operation',
        width: 50,
        align: 'center',
        render: (text, record) => <Checkbox disabled={record.disabled} checked={record.selected} onChange={ (e) => toggleWorkGroup(e, rowData.id, record.id) } />
      },
      { title: 'หมวดงาน', dataIndex: 'name', key: 'name', width: 200 },
    ];

    const data = (rowData.subWorkGroupPermissions || []).map(x => ({
      key: x.id,
      ...x,
    }));
    return <Table className="nested-table" columns={columns} dataSource={data} pagination={false} />;
  };

  return (
    <Table
      pagination={{
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "20", "100"],
      }}
      columns={columns}
      dataSource={datasource}
      bordereds
      rowKey="id"
      expandable={{expandedRowRender}}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            // console.log("record ", record);
            // console.log("rowIndex ", rowIndex);
          }, // click row
        };
      }}
    />
  );
};

export default Component;
