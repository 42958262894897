import React, { useState } from "react";
import { Form, Checkbox, Row, Col, Input } from "antd";
import ButtonSave from "../../../../Components/ButtonSave";

const CheckBoxSubWorkGroup = ({
  selectedSubWorkGroups,
  allSubWorkGroups,
  onChangeCheckboxSubWorkGroup,
}) => {
  function onChange(checkedValues) {
    onChangeCheckboxSubWorkGroup(checkedValues);
  }

  return (
    <div>
      <Form.Item>
        <Checkbox.Group value={selectedSubWorkGroups} onChange={onChange}>
          {allSubWorkGroups.map((subWorkGroup) => (
            <Row key={subWorkGroup.value}>
              <Checkbox
                value={subWorkGroup.value}
                className="font-common size-common"
              >
                {subWorkGroup.name}
              </Checkbox>
            </Row>
          ))}
        </Checkbox.Group>
      </Form.Item>
      <Form.Item style={{ paddingTop: "5px", backgroundColor: "" }}>
        <ButtonSave label="บันทึก" width="100%"></ButtonSave>
      </Form.Item>
    </div>
  );
};

export default CheckBoxSubWorkGroup;
