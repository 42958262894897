import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import NavBar2 from "../../Components/NavBar2";
import UserInfoForm from "./Components/UserInfoForm";
// import AuthHelper from '../../Helper/AuthHelper';
import { Row, Layout, Col } from "antd";
import { Background } from "../../Constants/Images";

const Register = (props) => {
  // const { projectId } =;

  useEffect(() => {
    (async () => {
      // console.log("p id ", projectId);
      // if (parseInt(projectId)) {
      //   console.log("get project info");
      //   await getProject(projectId);
      // }
      // await getProvince();
    })();
  }, []);

  return (
    <div>
      <NavBar2 labelEnglish={"Register"} labelThai={"ลงทะเบียน"}></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "60px", paddingTop: "0px" }}
            >
              <UserInfoForm router={props.history} isEdit={false} />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Register;
