// export const capsule_dark = require("../Assets/Images/capsule_dark.png");
import AppConfig from "../AppConfig";
export const Background = AppConfig.imageUrl + "/bg.png";
export const BackgroundLogin = AppConfig.imageUrl + "/login_bg.png";
export const LogoMain = AppConfig.imageUrl + "/logoMain.png";
export const LogoLogin = AppConfig.imageUrl + "/logo_login.png";
export const BackgroundDark = AppConfig.imageUrl + "/dark_bg.png";
export const Building = AppConfig.imageUrl + "/Building.png";
export const WaterSystem = AppConfig.imageUrl + "/WaterSystem.png";
export const Dam = AppConfig.imageUrl + "/Dam.png";
export const DevelopCity = AppConfig.imageUrl + "/DevelopCity.png";
export const HomeIcon = AppConfig.imageUrl + "/HomeIcon.png";
