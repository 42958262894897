import React from "react";
import { Form, Input, Col, Row } from "antd";

const InputLogin = ({ label, type, keyInput }) => {
  return (
    <Row
      style={{
        background: "linear-gradient(#d53102, #ff6e00)",
        width: "100%",
        height: "50px",
        borderRadius: "50px",
        padding: "3px",
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: "2px",
      }}
    >
      <Col xs={10} xl={6} style={{ background: "" }}>
        <label
          className="font-common"
          style={{
            backgroundColor: "black",
            borderRadius: "50px",
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "20px",
            color: "#E54901",
          }}
        >
          {label}
        </label>
      </Col>
      <Col xs={10} xl={14}>
        <Form.Item name={keyInput}>
          <Input
            className="font-common"
            style={{
              backgroundColor: "rgba(0,0,0,0)",
              fontSize: "20px",
              color: "white",
            }}
            bordered={false}
            type={type}
          ></Input>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InputLogin;
