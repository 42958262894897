import React, { Component } from "react";
import { Route } from "react-router-dom";
import Info from "../Pages/Project/Info";
import Material from "../Pages/Project/Material";
import PR2 from "../Pages/Project/PR2";
import PR4 from "../Pages/Project/PR4";
import PR5 from "../Pages/Project/PR5";
import PR6 from "../Pages/Project/PR6";
import Category from "../Pages/Project/Category";
import Picture from "../Pages/Project/Picture";
import AppConfig from "../AppConfig";
import Location from "../Pages/Project/Location";
import InfoBk from "../Pages/Project/Info/indexBk";
import ConceteCost from "../Pages/Project/ConceteCost";
import PR4Special from "../Pages/Project/PR4/indexSpecial";
import SummaryPR4 from "../Pages/Project/SummaryPR4";
export default class ProjectRoutes extends Component {
  render() {
    return (
      <span>
        <Route exact path={AppConfig.appPath + "/project/"} component={Info} />
        <Route
          exact
          path={AppConfig.appPath + "/project/select/category"}
          component={Category}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/picture"}
          component={Picture}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/info"}
          component={Info}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/infoBK"}
          component={InfoBk}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/location"}
          component={Location}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/material"}
          component={Material}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/pr2"}
          component={PR2}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/pr4"}
          component={PR4}
        />
        <Route
          exact
          path={
            AppConfig.appPath +
            "/project/:projectId/pr4/subWorkGroup/:subWorkGroupId"
          }
          component={PR4Special}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/pr5"}
          component={PR5}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/pr6"}
          component={PR6}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/ConceteCost"}
          component={ConceteCost}
        />
        <Route
          exact
          path={AppConfig.appPath + "/project/:projectId/SummaryPR4"}
          component={SummaryPR4}
        />
      </span>
    );
  }
}
