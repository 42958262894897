import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Form, Row, Col, Input, InputNumber, Select, DatePicker } from "antd";
import InputRowProjectInfoBK from "./InputRowProjectInfoBK";
import DragImage from "./DragImage";
import ButtonSave from "../../../../Components/ButtonSave";
import HomeButton from "../../../../Components/HomeButton";
import VerticalLineProjectInfo from "./VerticalLineProjectInfo";
const { Option } = Select;

const ProjectInfoFormBK = () => {
  const [form] = Form.useForm();
  const provinces = [
    { label: "กรุงเทพ", value: "กรุงเทพ" },
    { label: "ชัยภูมิ", value: "ชัยภูมิ" },
    { label: "ขอนแก่น", value: "ขอนแก่น" },
  ];

  const trucks = [
    { label: "รถบรรทุก 6 ล้อ", value: "รถบรรทุก 6 ล้อ" },
    { label: "รถบรรทุก 10 ล้อ", value: "รถบรรทุก 10 ล้อ" },
    { label: "รถบรรทุก 10 ล้อพ่วง", value: "รถบรรทุก 10 ล้อพ่วง" },
  ];

  useEffect(() => {});

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <InputRowProjectInfoBK
        label="ชื่อโครงการ"
        keyInput="name"
        InputComponent={<Input placeholder="ชื่อโครงการ"></Input>}
        isRequire
        messageRequire="กรุณาใส่ชื่อโครงการ"
      />
      <InputRowProjectInfoBK
        label="ประเภทโครงการ"
        keyInput="type"
        InputComponent={
          <Select allowClear placeholder="ประเภท">
            <Option value="ชลประทาน">ชลประทาน</Option>
            <Option value="อาคาร">อาคาร</Option>
          </Select>
        }
      />
      <InputRowProjectInfoBK
        label="เจ้าของโครงการ"
        keyInput="owner"
        InputComponent={<Input placeholder="เจ้าของโครงการ"></Input>}
      />
      <InputRowProjectInfoBK
        label="ที่อยู่"
        keyInput="address"
        InputComponent={<Input placeholder="ที่อยู่"></Input>}
      />
      <InputRowProjectInfoBK
        label="จังหวัดโครงการ"
        keyInput="pronvice"
        InputComponent={
          <Select
            allowClear
            showSearch
            options={provinces}
            placeholder="จังหวัดโครงการ"
          ></Select>
        }
      />
      <InputRowProjectInfoBK
        label="จังหวัดข้างเคียง 1"
        keyInput="pronvice1"
        InputComponent={
          <Select
            allowClear
            showSearch
            options={provinces}
            placeholder="จังหวัดข้างเคียง 1"
          ></Select>
        }
      />
      <InputRowProjectInfoBK
        label="จังหวัดข้างเคียง 2"
        keyInput="pronvice2"
        InputComponent={
          <Select
            allowClear
            showSearch
            options={provinces}
            placeholder="จังหวัดข้างเคียง 2"
          ></Select>
        }
      />
      <InputRowProjectInfoBK
        label="หน่วยงาน"
        keyInput="department"
        InputComponent={<Input placeholder="หน่วยงาน"></Input>}
      />
      <InputRowProjectInfoBK
        label="แบบเลขที่"
        keyInput="code"
        InputComponent={<Input placeholder="แบบเลขที่"></Input>}
      />
      <InputRowProjectInfoBK
        label="วันที่"
        keyInput="date"
        InputComponent={
          <DatePicker
            placeholder={"วันที่ทำการประเมิน"}
            style={{ width: "100%", fontSize: "14px", borderRadius: "25px" }} //fontsize icon not relate input
            size="large"
          ></DatePicker>
        }
      />
      <InputRowProjectInfoBK
        label="ราคาน้ำมันดีเซล B7"
        keyInput="disealB7"
        InputComponent={
          <InputNumber
            placeholder="ราคาน้ำมันดีเซล B7"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%", borderRadius: "25px" }}
          />
        }
      />
      <InputRowProjectInfoBK
        label="ราคาน้ำมัน Gasohol 91"
        keyInput="Gasohol 91"
        InputComponent={
          <InputNumber
            placeholder="ราคาน้ำมัน Gasohol 91"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%", borderRadius: "25px" }}
          />
        }
      />
      <InputRowProjectInfoBK
        label="รถบรรทุก"
        keyInput="truck"
        InputComponent={
          <Select
            allowClear
            showSearch
            options={trucks}
            placeholder="รถบรรทุก"
          ></Select>
        }
      />
      <InputRowProjectInfoBK
        label="เงินล่วงหน้า"
        keyInput="advanceMoney"
        InputComponent={
          <InputNumber
            placeholder="เงินล่วงหน้า"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%", borderRadius: "25px" }}
          />
        }
      />
      <InputRowProjectInfoBK
        label="เงินประกัน"
        keyInput="insurance"
        InputComponent={
          <InputNumber
            placeholder="เงินประกัน"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%", borderRadius: "25px" }}
          />
        }
      />
      <InputRowProjectInfoBK
        label="ดอกเบี้ย"
        keyInput="interest"
        InputComponent={
          <InputNumber
            placeholder="ดอกเบี้ย"
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%", borderRadius: "25px" }}
          />
        }
      />
      <InputRowProjectInfoBK
        label="ภาษีมูลค่าเพิ่ม"
        keyInput="vat"
        InputComponent={
          <Input
            type="number"
            placeholder="VAT"
            suffix="%"
            style={{ width: "100%", borderRadius: "25px" }}
          />
        }
      />
      <InputRowProjectInfoBK
        label="ภาพโครงการ"
        keyInput="image"
        InputComponent={<DragImage />}
      />
      <VerticalLineProjectInfo />
      <VerticalLineProjectInfo />
      <VerticalLineProjectInfo
        childComponent={
          <Form.Item style={{ paddingTop: "50px", backgroundColor: "" }}>
            <ButtonSave label="บันทึก" width="100%"></ButtonSave>
          </Form.Item>
        }
      />
      <VerticalLineProjectInfo />

      <Row>
        <Col xs={12} xl={3}></Col>
        <Col
          xs={12}
          xl={2}
          style={{
            backgroundColor: "",
            marginLeft: "-35px",
            marginTop: "-10px",
          }}
        >
          <HomeButton />
        </Col>
      </Row>

      {/* <Row style={{ marginTop: "60px", height: "100px" }}>
        <Col xs={22} xl={6}>
          <Form.Item style={{ paddingTop: "50px", backgroundColor: "" }}>
            <ButtonSave label="บันทึก" width="100%"></ButtonSave>
          </Form.Item>
        </Col>
      </Row> */}
    </Form>
  );
};

export default ProjectInfoFormBK;
