import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../Redux/LoadingReducer";
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col, Tree, Modal, Input, Form } from "antd";
import { Background } from "../../../Constants/Images";
import TablePR4 from "./Components/TablePR4";
import ButtonSubCategory from "./Components/ButtonSubCategory";
import MenuStep from "../../../Components/MenuStep";
import ButtonRound from "../../../Components/ButtonRound";
import ButtonWorkGroup from "./Components/ButtonWorkGroup";
// import ButtonSave from "../../../Components/ButtonSave";
// import ButtonCancel from "../../../Components/ButtonCancel";
import HTTP from "../../../https/HTTP";
import HTTPUtils from "../../../https/HTTPUtils";
import AppConfig from "../../../AppConfig";
import CheckBoxSubWorkGroup from "./Components/CheckBoxSubWorkGroup";
import {
  Irrigation,
  Dam,
  Building,
  Etc,
  Area,
  Special,
} from "../../../Constants/SubWorkGroupId";
import { columnDam, columnBuilding } from "../../../Constants/ColumnFormat";
// import { getType } from "@reduxjs/toolkit";
// const { TabPane } = Tabs;
const PR4 = (props) => {
  const dispatch = useDispatch();
  // const [project, setProject] = useState({});
  const [isShowModalWorkGroup, setIsShowModalWorkGroup] = useState(false);
  const [selectedSubWorkGroups, setSelectedSubWorkGroups] = useState([]);
  const [allSubWorkGroups, setAllSubWorkGroups] = useState([]);
  const [workGroupModal, setWorkGroupModal] = useState(null);
  const [workGroupTreeMenu, setWorkGroupTreeMenu] = useState([]);
  const [formWorkGroup] = Form.useForm();
  const [selectedWorkGroupSubWorGroup, setSelectedWorkGroupSubWorGroup] =
    useState({});
  const [workGroupResponse, setWorkGroupResponse] = useState({});
  // const [workGroupAndSubWorkGroupId, setWorkGroupAndSubWorkGroupId] = useState(
  //   {}
  // );
  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoading(true));
        const projectId = props.match.params.projectId;
        await getAllSubWorkGroup(projectId);
        await getWorkGroups(projectId);
        dispatch(showLoading(false));
      } catch {
        dispatch(showLoading(false));
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      // await getWorkGroups(props.match.params.projectId); // TODO render only
      renderWorkGroupMenu(workGroupResponse);
    })();
  }, [selectedWorkGroupSubWorGroup]);

  useEffect(() => {
    // (async () => {
    //   // await getWorkGroups(props.match.params.projectId); // TODO render only
    //   renderWorkGroupMenu(workGroupResponse);
    // })();
    if (isShowModalWorkGroup) {
      if (workGroupModal) {
        formWorkGroup.setFieldsValue({
          name: workGroupModal?.name,
          id: workGroupModal?.id,
        });
      }
    }
  }, [isShowModalWorkGroup, workGroupModal]);

  const getAllSubWorkGroup = async (projectId) => {
    const http = HTTP("GET", "project/" + projectId);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      const typeCategories = response.data.type.typeCategories;
      const allSubWorkGroups = typeCategories.map((tc) => {
        return tc.category.subWorkGroups.map((swg) => {
          return {
            value: swg.id,
            name: swg.name,
          };
        });
      });

      var mergedAllSubWorkGroups = [].concat.apply([], allSubWorkGroups);
      setAllSubWorkGroups(mergedAllSubWorkGroups);
    } else {
      //show error
    }
  };

  const getWorkGroups = async (projectId) => {
    const http = HTTP("GET", "workgroup/project/" + projectId);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      const workGroups = response.data;
      renderWorkGroupMenu(workGroups);
      setWorkGroupResponse(workGroups);
    } else {
      //show error
    }
  };

  const getWorkGroup = async (id) => {
    const http = HTTP("GET", "workgroup/" + id);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      return response.data;
    } else {
      //show error
    }
  };

  const renderWorkGroupMenu = (workGroups) => {
    let workgroupMenu = [];
    // console.log(workGroups);
    if (Object.keys(workGroups).length > 0) {
      workGroups.forEach((workgroup) => {
        if (
          workgroup.subWorkGroups.filter(
            (e) => e.id === Area || e.id === Special
          ).length > 0
        ) {
          return;
        }

        let treeMain = {
          title: (
            <div id={`workGroupId${workgroup.id}`}>
              <ButtonWorkGroup
                label={workgroup.name}
                onClick={(e) => {
                  // const workGroupId =
                  //   e.currentTarget.getAttribute("workgroupid");
                  setSelectedWorkGroupSubWorGroup({
                    workGroupId: workgroup.id,
                    subWorkGroupId: 0,
                  });
                }}
                workgroupid={workgroup.id}
                selectedWorkGroupSubWorGroup={selectedWorkGroupSubWorGroup}
              ></ButtonWorkGroup>
            </div>
          ),
          key: `workGroupId${workgroup.id}`,
          children: [],
        };

        let subWorkGroups = workgroup.subWorkGroups.map((swg) => {
          return {
            title: (
              <>
                <ButtonSubCategory
                  label={swg.name}
                  selectedWorkGroupSubWorGroup={selectedWorkGroupSubWorGroup}
                  onClick={(e) => {
                    // const workGroupId =
                    //   e.currentTarget.getAttribute("workgroupid");
                    // const swgId =
                    //   e.currentTarget.getAttribute("subworkgroupid");
                    // console.log(
                    //   `workgroupId = ${workgroup.id} subWorkGroupId = ${swg.id}`
                    // );
                    setSelectedWorkGroupSubWorGroup({
                      workGroupId: workgroup.id,
                      subWorkGroupId: swg.id,
                    });
                  }}
                  workgroupid={workgroup.id}
                  subworkgroupid={swg.id}
                ></ButtonSubCategory>
              </>
            ),
            key: `workGroupId${workgroup.id}_subWorkGroupId${swg.id}`,
          };
        });

        const userId = parseInt(window.sessionStorage?.userId || 0, 10);
        if (workgroup.project.userId === userId) {
          subWorkGroups.push({
            title: (
              <>
                <ButtonSubCategory
                  label="แก้ไข"
                  onClick={(e) => {
                    const workGroupId =
                      e.currentTarget.getAttribute("workgroupid");
                    openModalEditWorkGroup(workGroupId);
                  }}
                  workgroupid={workgroup.id}
                ></ButtonSubCategory>
              </>
            ),
            key: `workGroupId${workgroup.id}_edit`,
          });          
        }

        treeMain.children = subWorkGroups;
        workgroupMenu.push(treeMain);
      });
    }

    setWorkGroupTreeMenu(workgroupMenu);
  };

  const openModalEditWorkGroup = async (workGroupId) => {
    //TODO workGroupResponse dosen't set if debug mode it will set !! workaround by get from api
    // if (Object.keys(workGroupResponse).length > 0) {
    // const workGroupData = [...workGroupResponse];
    // const workGroups = workGroupData.filter((wg) => wg.id == workGroupId);
    const workGroupResponse = await getWorkGroup(workGroupId);
    // console.log(workGroupResponse);
    if (workGroupResponse) {
      setWorkGroupModal({ name: workGroupResponse.name, id: workGroupId });
      const subWorkGroupIds = workGroupResponse.subWorkGroups.map(
        (swg) => swg.id
      );
      setSelectedSubWorkGroups(subWorkGroupIds);
      // console.log(subWorkGroupIds);
    }

    setIsShowModalWorkGroup(true);
  };

  const dismissModalWorkGroup = () => {
    formWorkGroup.resetFields();
    setWorkGroupModal(null);
    setSelectedSubWorkGroups([]);
    setIsShowModalWorkGroup(false);
  };

  const onFinishModalWorkGroup = async (values) => {
    setIsShowModalWorkGroup(false);
    dispatch(showLoading(true));
    // console.log("Received values of form: ", values);
    // console.log(workGroupModal);
    const projectId = props.match.params.projectId;
    const workGroupId = parseInt(workGroupModal?.id);
    // console.log(`workGroupId=${workGroupId}`);
    if (isNaN(workGroupId)) {
      const createRequest = {
        ...values,
        projectId: projectId,
        SubWorkGroupIds: selectedSubWorkGroups,
      };
      await createWorkGroup(createRequest);
    } else {
      const updateRequest = {
        ...values,
        projectId: projectId,
        SubWorkGroupIds: selectedSubWorkGroups,
        id: workGroupId,
      };
      await updateWorkGroup(updateRequest);
    }

    formWorkGroup.resetFields();
    setWorkGroupModal(null);
    setSelectedSubWorkGroups([]);
    await getWorkGroups(projectId);
    dispatch(showLoading(false));
  };

  const onChangeCheckboxSubWorkGroup = (checkedValues) => {
    //array subworkgroup id
    setSelectedSubWorkGroups(checkedValues);
  };

  const createWorkGroup = async (request) => {
    const http = HTTP("POST", "workgroup/create", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("สำเร็จ");
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const updateWorkGroup = async (request) => {
    const http = HTTP("POST", "workgroup/update", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("สำเร็จ");
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const SelectedColumnFormat = () => {
    const subWorkGroupId = selectedWorkGroupSubWorGroup.subWorkGroupId;
    if (subWorkGroupId == Irrigation || subWorkGroupId == Dam) {
      return columnDam;
    } else if (subWorkGroupId >= Building && subWorkGroupId <= Etc) {
      return columnBuilding;
    } else {
      return [];
    }
  };

  return (
    <div>
      <NavBar2 labelEnglish={"PR4"} labelThai={"ปร.4"}></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep
                selectedIndex={3}
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "30px", paddingTop: "20px" }}
            >
              <Row>
                <Col
                  xl={4}
                  style={{
                    backgroundColor: "",
                    marginLeft: "-10px",
                  }}
                >
                  <Row>
                    <Col
                      xl={24}
                      style={{
                        backgroundColor: "",
                      }}
                    >
                      <Row>
                        <ButtonRound
                          label={"เพิ่มกลุ่มงาน"}
                          fontSize={"20px"}
                          background={"linear-gradient(#8dfa08, #0d9951)"}
                          // height={"70%"}
                          fontColor={"white"}
                          borderColor={"white"}
                          borderWidth={"4px"}
                          labelPaddingTop={"15px"}
                          // form={form}
                          width={"80%"}
                          onClick={() => {
                            setIsShowModalWorkGroup(true);
                          }}
                        ></ButtonRound>
                      </Row>
                      <Row style={{ marginTop: "5px" }}>
                        <ButtonRound
                          label={"ค่างานต้นทุนคอนกรีตเสริมเหล็ก"}
                          fontSize={"17px"}
                          background={"linear-gradient(#8dfa08, #0d9951)"}
                          // height={"70%"}
                          fontColor={"white"}
                          borderColor={"white"}
                          borderWidth={"4px"}
                          labelPaddingTop={"15px"}
                          // form={form}
                          href={`${AppConfig.appPath}/project/${props.match.params?.projectId}/conceteCost`}
                          width={"95%"}
                          // onClick={() => {
                          //   setIsShowModalWorkGroup(true);
                          // }}
                        ></ButtonRound>
                      </Row>
                    </Col>
                    <Col
                      xl={24}
                      style={{
                        backgroundColor: "",
                      }}
                    >
                      <Tree
                        showIcon
                        defaultExpandAll
                        // defaultSelectedKeys={["0-0-0"]}
                        treeData={workGroupTreeMenu}
                        // treeData={treeDataTemp}
                        style={{ marginTop: "25px" }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={20}
                  style={{
                    backgroundColor: "",
                  }}
                >
                  <TablePR4
                    selectedGroupProp={selectedWorkGroupSubWorGroup}
                    projectId={props.match.params.projectId}
                    columnFormat={SelectedColumnFormat()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          {/* <TablePR4
            selectedWorkGroupSubWorGroup={selectedWorkGroupSubWorGroup}
            projectId={props.match.params.projectId}
          /> */}
        </Layout.Content>
      </Layout>

      <Modal
        forceRender={true}
        title={
          <label
            className="font-common"
            style={{ fontSize: "30px", paddingTop: "15px" }}
          >
            กลุ่มงาน
          </label>
        }
        visible={isShowModalWorkGroup}
        // onOk={handleOk}
        onCancel={dismissModalWorkGroup}
        footer={null}
      >
        <Form
          onFinish={onFinishModalWorkGroup}
          form={formWorkGroup}
          // initialValues={{ Name: "1234" }}
          // initialValues={{
          //   // id1: true,
          //   name1: "1234",
          // }}
        >
          <Form.Item name={"name"}>
            <Input placeholder={"ชื่อกลุ่มงาน"}></Input>
          </Form.Item>
          {/* <Form.Item name={"id"}>
            <Input></Input>
          </Form.Item> */}
          <CheckBoxSubWorkGroup
            selectedSubWorkGroups={selectedSubWorkGroups}
            allSubWorkGroups={allSubWorkGroups}
            onChangeCheckboxSubWorkGroup={onChangeCheckboxSubWorkGroup}
          ></CheckBoxSubWorkGroup>
        </Form>
      </Modal>
    </div>
  );
};

export default PR4;
