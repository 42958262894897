import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "antd/dist/antd.css";
import { Form, Row, Col, Input } from "antd";
import InputRow from "./InputRow";
import ButtonSave from "../../../Components/ButtonSave";
import HTTP from "../../../https/HTTP";
import HTTPUtils from "../../../https/HTTPUtils";
import { showLoading } from "../../../Redux/LoadingReducer";
import AppConfig from "../../../AppConfig";

const UserInfoForm = ({ router, isEdit }) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        const userId = window.sessionStorage?.userId;
        if (isEdit && userId) {
          dispatch(showLoading(true));
          await getUser(userId);
          dispatch(showLoading(false));
        }
        // dispatch(showLoading(false));
      } catch {
        //display error
        // dispatch(showLoading(false));
      }
    })();
  }, []);

  const onFinish = async (submitData) => {
    dispatch(showLoading(true));
    if (!isEdit) {
      await createUser(submitData);
    } else {
      // submitData.userId = userId;
      console.log(window.sessionStorage.userId);
      submitData.id = window.sessionStorage.userId;
      await updateUser(submitData);
    }
    // console.log("Received values of form: ", values);
    // const projectId = parseInt(urlParams.projectId);
    // console.log(projectId);
    // if (isNaN(projectId)) {
    //   const createRequest = { ...values, userId: 1 };
    //   await createProject(createRequest);
    // } else {
    //   const updateRequest = { ...values, userId: 1, id: projectId };
    //   await updateProject(updateRequest);
    // }

    dispatch(showLoading(false));
  };

  const createUser = async (request) => {
    const http = HTTP("POST", "user/create", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("สำเร็จ");
      router.replace(
        // AppConfig.appPath + "/project/select/category"
        AppConfig.appPath
      );
    } else if (response.status == 409) {
      //show error
      toast.error(
        "user name ที่กรอกมีอยู่แล้วในระบบ โปรดเปลี่ยน user name ใหม่"
      );
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const updateUser = async (request) => {
    const http = HTTP("POST", "user/update", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("สำเร็จ");
    } else if (response.status == 409) {
      //show error
      toast.error(
        "user name ที่กรอกมีอยู่แล้วในระบบ โปรดเปลี่ยน user name ใหม่"
      );
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const getUser = async (userId) => {
    const http = HTTP("GET", "user/info/" + userId);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      console.log(response.data);
      // const project = responseToProjectFormData(response.data);
      // setProject(response.data);
      form.setFieldsValue(response.data);
    } else {
      //show error
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      // initialValues={{
      //   typeId: "1",
      // }}
      // initialValues={project}
    >
      <InputRow
        label="User name"
        keyInput="userName"
        InputComponent={<Input placeholder="user name"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />
      {isEdit ? null : (
        <InputRow
          label="Password"
          keyInput="password"
          InputComponent={
            <Input
              placeholder="password"
              type="password"
              // hidden={isEdit}
              // disabled={isEdit}
            ></Input>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
      )}

      <InputRow
        label="ชื่อ"
        keyInput="firstName"
        InputComponent={<Input placeholder="ชื่อ"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />
      <InputRow
        label="นามสกุล"
        keyInput="lastName"
        InputComponent={<Input placeholder="นามสกุล"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />
      <InputRow
        label="เลขบัตรประชาชน"
        keyInput="idCard"
        InputComponent={<Input placeholder="เลขบัตรประชาชน"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />
      <InputRow
        label="แผนก"
        keyInput="department"
        InputComponent={<Input placeholder="แผนก"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />
      <InputRow
        label="ตำแหน่ง"
        keyInput="occupation"
        InputComponent={<Input placeholder="ตำแหน่ง"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />
      <InputRow
        label="เบอร์มือถือ"
        keyInput="mobile"
        InputComponent={<Input placeholder="เบอร์มือถือ"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />
      <InputRow
        label="Email"
        keyInput="email"
        InputComponent={<Input placeholder="Email"></Input>}
        isRequire
        messageRequire="กรุณากรอกข้อมูล"
      />

      <Row style={{ height: "60px" }}>
        <Col xl={3} style={{ textAlign: "right", backgroundColor: "" }}></Col>
        <Col
          xs={2}
          xl={2}
          style={{ marginLeft: "10px", backgroundColor: "" }}
        ></Col>
        <Col
          xs={22}
          xl={4}
          md={5}
          style={{ marginTop: "34px", backgroundColor: "" }}
        >
          <Form.Item style={{ paddingTop: "50px", backgroundColor: "" }}>
            <ButtonSave label="บันทึก" width="100%"></ButtonSave>
          </Form.Item>
        </Col>
      </Row>

      {/* <Row style={{ marginTop: "60px", height: "100px" }}>
        <Col xs={22} xl={6}>
          <Form.Item style={{ paddingTop: "50px", backgroundColor: "" }}>
            <ButtonSave label="บันทึก" width="100%"></ButtonSave>
          </Form.Item>
        </Col>
      </Row> */}
    </Form>
  );
};

export default UserInfoForm;
