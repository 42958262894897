import React from "react";
import "../Styles/VerticalLine.css";

const VerticalLine = ({}) => {
  return (
    <table align="">
      <tbody>
        <tr>
          <td>
            <div className="background2" style={{ backgroundColor: "" }}>
              <div className="line1"></div>
            </div>
          </td>
          <td>
            <div className="background2" style={{ backgroundColor: "" }}></div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default VerticalLine;
