import React from "react";
// import { toast } from 'react-toastify';
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col, Image } from "antd";
import { Background } from "../../../Constants/Images";
import MenuStep from "../../../Components/MenuStep";
const Picutre = (props) => {
  // componentDidMount = () => {
  //   const { projectId } = this.props.match.params;
  // };

  return (
    <div>
      <NavBar2 labelEnglish={"รูป"} labelThai={"Picture"}></NavBar2>

      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep selectedIndex={null} projectId={1}></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "60px", paddingTop: "20px" }}
            >
              <Image
                src="https://www.ubm-development.com/magazin/wp-content/uploads/2020/03/kl-main-building-d-Kopie.jpg"
                preview={false}
              ></Image>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Picutre;
