import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../Redux/LoadingReducer";
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col } from "antd";
import { Background } from "../../../Constants/Images";
import MenuStep from "../../../Components/MenuStep";
import TablePR5 from "./Components/TablePR5";
import {
  columnPR5Dam,
  columnPR5Building,
} from "../../../Constants/ColumnFormat";
import { Building, DevCity } from "../../../Constants/Type";
import HTTP from "../../../https/HTTP";
import HTTPUtils from "../../../https/HTTPUtils";

const PR5 = (props) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [isBuildingType, setIsBuildingType] = useState(false);
  useEffect(() => {
    (async () => {
      dispatch(showLoading(true));
      const projectId = props.match.params.projectId;
      await getProject(projectId);
      dispatch(showLoading(false));
    })();
  }, []);

  const selectedColumnFormat = () => {
    if (isBuildingType) {
      return columnPR5Building;
    } else {
      return columnPR5Dam;
    }
  };

  const getProject = async (projectId) => {
    const http = HTTP("GET", `project/${projectId}`);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      var typeId = response.data.typeId;
      if (typeId == Building || typeId == DevCity) {
        setIsBuildingType(true);
        // await getSummaryPR4Building(projectId, workGroups[0]?.value);
      } else {
        // await getSummaryPR4Dam(projectId);
      }

      await getSummaryPR5(projectId);
    } else {
      toast.error("ไม่พบข้อมูล");
    }
  };

  const getSummaryPR5 = async (projectId) => {
    const http = HTTP("GET", `projectItem/summary/pr5/project/${projectId}`);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      setTableData(response.data?.workGroupSummaries);
    } else {
      //show error
      toast.error("ขอข้อมูลล้มเหลว");
    }
  };

  return (
    <div>
      <NavBar2 labelEnglish={"PR5"} labelThai={"ปร.5"}></NavBar2>

      <Layout
        className="background"
        style={{
          backgroundImage: `url("${Background}")`,
        }}
      >
        <Layout.Content>
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep
                selectedIndex={7}
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={20}
              style={{ paddingLeft: "60px", paddingTop: "20px" }}
            >
              <Row>
                <Col xl={24}>
                  <TablePR5
                    projectId={props.match.params.projectId}
                    columnFormat={selectedColumnFormat()}
                    data={tableData}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default PR5;
