import React from "react";
// import { toast } from 'react-toastify';
import { Button, Row, Col } from "antd";
import InputSearchFooter from "./InputSearchFooter";
// import AuthHelper from '../../Helper/AuthHelper';

const SearchBar = ({}) => {
  return null;
  // return (
  //   <Row>
  //     <Col xs={0} xl={4}></Col>
  //     <Col
  //       xs={18}
  //       xl={8}
  //       style={{
  //         background: "linear-gradient(#fcfcfc,#bfbfbf)",
  //         borderRadius: "50px",
  //         height: "70px",
  //         width: "70px",
  //       }}
  //     >
  //       <Row>
  //         <Col
  //           xs={14}
  //           xl={17}
  //           style={{
  //             paddingTop: "11px",
  //             paddingLeft: "15px",
  //           }}
  //         >
  //           <InputSearchFooter label="ค้นหา"></InputSearchFooter>
  //         </Col>
  //         <Col
  //           xs={8}
  //           xl={6}
  //           style={{
  //             paddingTop: "15px",
  //             marginLeft: "10px",
  //           }}
  //         >
  //           <Button
  //             className="font-common"
  //             style={{
  //               borderRadius: "5px",
  //               color: "white",
  //               backgroundColor: "black",
  //               borderWidth: "2px",
  //               borderColor: "white",
  //               width: "70%",
  //               height: "60%",
  //             }}
  //           >
  //             <label
  //               style={{
  //                 paddingBottom: "15px",
  //                 fontSize: "20px",
  //                 fontWeight: "",
  //               }}
  //             >
  //               PRINT
  //             </label>
  //           </Button>
  //         </Col>
  //       </Row>
  //     </Col>
  //     <Col xs={0} xl={4}></Col>
  //   </Row>
  // );
};

export default SearchBar;
