import React from "react";
import "antd/dist/antd.css";
import { Upload } from "antd";
import { CameraOutlined } from "@ant-design/icons";
const { Dragger } = Upload;
const DragImage = ({}) => {
  const propsDragger = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      //   if (status === "done") {
      //     message.success(`${info.file.name} file uploaded successfully.`);
      //   } else if (status === "error") {
      //     message.error(`${info.file.name} file upload failed.`);
      //   }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Dragger {...propsDragger}>
      <p className="ant-upload-drag-icon">
        <CameraOutlined style={{ paddingTop: "20px" }} />
      </p>
      {/* <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p> */}
    </Dragger>
  );
};

export default DragImage;
