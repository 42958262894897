import React from "react";
import { Button, Image } from "antd";
import AppConfig from "../AppConfig";
import { HomeIcon } from "../Constants/Images";

const HomeButton = () => {
  return (
    <Button
      style={{
        background: "linear-gradient(#85f300,#34b600)",
        borderRadius: "50px",
        width: "70px",
        height: "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderColor: "black",
        borderWidth: "4px",
      }}
      href={AppConfig.appPath + "/home"}
    >
      <Image src={HomeIcon} width="50px" preview={false}></Image>
    </Button>
  );
};

export default HomeButton;
