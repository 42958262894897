import React, { useEffect } from "react";
import { Row, Col } from "antd";
import ButtonMenuLeftSide from "./ButtonMenuLeftSide";
import CrossLine from "./CrossLine";
const MenuStepRow = ({ label, buttonStyle, href, onClick }) => {
  const rowHeigth = "47px";
  const paddingLeftLine = "13px";
  const circleColor = "black";
  useEffect(() => {}, []);

  return (
    // <Row>
    <Row style={{ height: rowHeigth, cursor: 'pointer' }} onClick={onClick}>
      <Col xs={10} xl={21}>
        <ButtonMenuLeftSide
          label={label}
          buttonProp={buttonStyle}
          href={href}
        ></ButtonMenuLeftSide>
      </Col>
      <Col
        xs={4}
        xl={3}
        style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
      >
        <CrossLine circleColor={circleColor} isRemoveHorizontalLine={true} />
      </Col>
    </Row>
  );
};

export default MenuStepRow;
