const targetEnvironment = "local";
const appVersion = "2.0.0";

// ตอนเปลี่ยน appPath ให้ไปเปลี่ยน homepage ที่ package.json ด้วย

// endpoint config
const serverUrl = {
  local: process.env.REACT_APP_API_URL || "http://localhost:44311",
  beta: "https://navyjone.com",
  production: "http://110.164.237.161/",
};

const appPath = {
  local: "",
  beta: "/gov-estimate",
  production: "/estimate",
};

const rootPath = {
  local: "/",
  beta: "/gov-estimate/",
  production: "/estimate/",
}

const apiPath = {
  local: serverUrl[targetEnvironment] + "/api/",
  beta: "https://navyjone.com/gov-estimate-api/api/",
  production: "http://110.164.237.161/estimate-api/api/",
};

const gpsWebPath = {
  local: "http://110.164.237.161/costgps/index.html#!/transCost",
  beta: "http://110.164.237.161/costgps/index.html#!/transCost",
  production: "http://110.164.237.161/costgps/index.html#!/transCost",
};

const AppConfig = {
  appVersion: appVersion,
  targetEnvironment: targetEnvironment,
  appPath: appPath[targetEnvironment],
  rootPath: rootPath[targetEnvironment],
  // localStorageKey: 'esky' + targetEnvironment,
  serverUrl: serverUrl[targetEnvironment],
  apiUrl: apiPath[targetEnvironment],
  imageUrl: appPath[targetEnvironment] + "/Images/",
  storeKeyPrefix: "ccwKey",
  gpsWebPath: gpsWebPath[targetEnvironment],
  googleMapApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
};

export default AppConfig;
