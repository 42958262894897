import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../../../../Styles/Table.css";
import { toast } from "react-toastify";
import { Col, Row, Table, Form, Modal, Input, Select, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../../Redux/LoadingReducer";
import ButtonSave from "../../../../Components/ButtonSave";
import api from "../../../../https/api";
import columns from './TableItem.columns';
import "./TableItem.css";

const TableItem = ({ projectId }) => {
  const dispatch = useDispatch();
  const [dataTable, setDataTable] = useState([]);
  const [dataTableDisplay, setDataTableDisplay] = useState([]);
  const [pr2Items, setPr2Items] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();

  function openModal(editingData) {
    form.resetFields();
    if (editingData?.children) {
      return;
    }
    if (editingData) {
      setEditData({ ...editingData });
    } else {
      setEditData({id: 0, itemPr2Id: null});
    }
    setModalVisible(true);
  }
  const [editData, setEditData] = useState({});

  function onValuesChange(data) {
    setEditData({...editData, ...data})
  }

  async function submitModalData(data) {
    try {
      const isEdit = data.id > 0;
      const result = await api.post(`project/${projectId}/pr2`, data)
      if (!isEdit) {
        setDataTable([...dataTable, result.data]);
      } else {
        const newState = [...dataTable];
        const editIndex = newState.findIndex(x => x.id === data.id);
        newState[editIndex] = result.data;
        setDataTable(newState);
      }
      toast.success("บันทึกสำเร็จ");
      setModalVisible(false);
    } catch {
      toast.error("บันทึกล้มเหลว");
    }

  }

  function closeModal() {    
    setModalVisible(false);
    form.resetFields();
  }
  
  useEffect(() =>  {
    const group = dataTable.reduce((prev, cur, index) => (
      { 
        ...prev,
        [cur.item.id]: {
          key: cur.item.id,
          name: cur.item.name,
          children: [
            ...(prev[cur.item.id] || {}).children || [],
            {
              ...cur,
              key: cur.item.id + '.' + String(((prev[cur.item.id] || {}).children || []).length + 1)
            }
          ]
        }
      }
    ), {});
    const values = Object.values(group);
    let jobNo = 1;
    for (const job of values) {
      job.no = jobNo;
      let itemNo = 1;
      for (const item of job.children) {
        item.no = `${jobNo}.${itemNo}`;
        itemNo += 1;
      }
      jobNo += 1;
    }
    setDataTableDisplay(values);
  }, [dataTable]);

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({ ...editData });
    }
  }, [modalVisible, editData, form])

  useEffect(() => {
    dispatch(showLoading(true));
    const getDetail = api.get(`project/${projectId}/pr2`)
      .then(result => {
        setDataTable(result.data);
        return result;
      })
    const getPr2Items = api.get('project/pr2-items')
      .then(result => {
        setPr2Items(result.data);
        return result;
      })
    Promise.all([getDetail, getPr2Items])
      .then((result) => {
        dispatch(showLoading(false));
      })
  }, [])

  const ruleRequired = {
    required: true,
    message: 'กรุณากรอกข้อมูล'
  };

  const formItemStyle = {
    marginBottom: '12px'
  };

  async function onDeleteRow(record) {
    dispatch(showLoading(true));
    const ids = record.children ? record.children.map(x => x.id) : [record.id];
    await api.request('delete', `project/${projectId}/pr2`, ids);
    
    try {
      // refresh new data from database
      const newList = await api.get(`project/${projectId}/pr2`)
      setDataTable(newList.data);
    } catch {
      toast.error("ล้มเหลว");
    }
    
    dispatch(showLoading(false));
  }

  const tableItemColumns = [
    ...columns,
    {
      title: "",
      width: 20,
      dataIndex: "operation",
      align: "center",
      render: (text, record) => (
        record.children ?
          <Popconfirm
            title={`ยืนยันการลบ (${record.children[0].item.name} จำนวน ${record.children.length} รายการ)`}
            onConfirm={async () => onDeleteRow(record)}
            okText="ตกลง"
            cancelText="ยกเลิก"
          >
            <DeleteOutlined
              style={{ fontSize: "20px", color: "black" }}
            ></DeleteOutlined>
          </Popconfirm>
          :
          <Popconfirm
            title={`ยืนยันการลบ (${record.name})`}
            onConfirm={async () => onDeleteRow(record)}
            okText="ตกลง"
            cancelText="ยกเลิก"
          >
            <DeleteOutlined
              style={{ fontSize: "20px", color: "black" }}
            ></DeleteOutlined>
          </Popconfirm>
      ),
    },
  ]

  return (
    <div>
      <Table
        pagination={{
          defaultPageSize: 5000,
        }}
        columns={tableItemColumns}
        dataSource={[...dataTableDisplay]}
        bordered
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (event.target.cellIndex < 16) {
                // if cell is not delete button
                openModal(record);
              }
            }, // click row
          };
        }}
        expandable={{
          expandIconColumnIndex: 1,
          indentSize: 5,
          rowExpandable: (record) => true,
        }}
        scroll={{ y: 460, x: 100 }}
        title={() => (
          <div>
            <Row>
              <Col xl={4}>
                <ButtonSave
                  label={"เพิ่มรายการ"}
                  onClick={() => {
                    openModal();
                  }}
                  width={"150px"}
                ></ButtonSave>
              </Col>
            </Row>
          </div>
        )}
      />
      <Modal
        forceRender={true}
        visible={modalVisible}
        title={
          <label
            className="font-common"
            style={{ fontSize: "30px", paddingTop: "15px" }}
          >
            {editData?.id > 0 ? 'แก้ไข' : 'เพิ่ม'}รายการ ปร.2
          </label>
        }
        // visible={isShowModalWorkGroup}
        // onOk={handleOk}
        width="800px"
        onCancel={closeModal}
        footer={null}
      >
        <Form
          layout="horizontal"
          labelCol={{ span:4 }}
          labelAlign="left"
          wrapperCol={{span: 10}}
          onFinish={submitModalData}
          form={form}
          colon={false}
          onValuesChange={onValuesChange}
        >
          <Form.Item hidden name={"id"}>
            <Input></Input>
          </Form.Item>
          <Form.Item name={"itemPr2Id"} label="ชื่องาน" rules={[ruleRequired]} style={formItemStyle}>
            <Select allowClear placeholder="ประเภท">
              {
                pr2Items.map((x) => <Select.Option key={x.id} value={x.id} > { x.name } </Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item name={"name"} label="ชื่อรายการ" rules={[ruleRequired]} style={formItemStyle}>
            <Input placeholder={"ชื่อรายการ"}></Input>
          </Form.Item>
          <Form.Item name={"concreteCum"} label="คอนกรีต (ลบ.ม.)" wrapperCol={{ span: 4 }} style={formItemStyle}>
            <Input placeholder={"คอนกรีต (ลบ.ม.)"} type="number"></Input>
          </Form.Item>
          <Form.Item name={"woodenModelSqm"} label="ไม้แบบ (ตร.ม.)" wrapperCol={{ span: 4 }} style={formItemStyle}>
            <Input placeholder={"woodenModelSqm"} type="number"></Input>
          </Form.Item>
          <Form.Item name={"crutchPcs"} label="ไม้ค้ำยัน (ต้น)" wrapperCol={{ span: 4 }} style={formItemStyle}>
            <Input placeholder={"crutchPcs"} type="number"></Input>
          </Form.Item>

          <Row>
            <Col span={12}>
              <h6>เหล็กเส้นกลมผิวเรียบ/เมตร</h6>
              <Form.Item name={"smoothBar6"} label="6 มม." style={formItemStyle}>
                <Input type="number" placeholder={"smoothBar6"}></Input>
              </Form.Item>
              <Form.Item name={"smoothBar9"} label="9 มม." style={formItemStyle}>
                <Input type="number" placeholder={"smoothBar9"}></Input>
              </Form.Item>
              <Form.Item name={"smoothBar12"} label="12 มม." style={formItemStyle}>
                <Input type="number" placeholder={"smoothBar12"}></Input>
              </Form.Item>
              <Form.Item name={"smoothBar15"} label="15 มม." style={formItemStyle}>
                <Input type="number" placeholder={"smoothBar15"}></Input>
              </Form.Item>
              <Form.Item name={"smoothBar19"} label="19 มม." style={formItemStyle}>
                <Input type="number" placeholder={"smoothBar19"}></Input>
              </Form.Item>
              <Form.Item name={"smoothBar25"} label="25 มม." style={formItemStyle}>
                <Input type="number" placeholder={"smoothBar25"}></Input>
              </Form.Item>              
            </Col>
            <Col span={12}>
              <h6>เหล็กเส้นกลมผิวข้ออ้อย/เมตร</h6>
              <Form.Item name={"deformedBar12"} label="12 มม." style={formItemStyle}>
                <Input type="number" placeholder={"deformedBar12"}></Input>
              </Form.Item>
              <Form.Item name={"deformedBar16"} label="16 มม." style={formItemStyle}>
                <Input type="number" placeholder={"deformedBar16"}></Input>
              </Form.Item>
              <Form.Item name={"deformedBar20"} label="20 มม." style={formItemStyle}>
                <Input type="number" placeholder={"deformedBar20"}></Input>
              </Form.Item>
              <Form.Item name={"deformedBar25"} label="25 มม." style={formItemStyle}>
                <Input type="number" placeholder={"deformedBar25"}></Input>
              </Form.Item>
              <Form.Item name={"deformedBar28"} label="28 มม." style={formItemStyle}>
                <Input type="number" placeholder={"deformedBar28"}></Input>
              </Form.Item>              
            </Col>
          </Row>
          <Form.Item wrapperCol={{ span: 24 }}>
            <ButtonSave type="primary" label={"บันทึก"} width="100%" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TableItem;
