import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "antd/dist/antd.css";
import {
  Form,
  Row,
  Col,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Popconfirm,
  Modal,
} from "antd";
import InputRowProjectInfo from "./InputRowProjectInfo";
import DragImage from "./DragImage";
import ButtonSave from "../../../../Components/ButtonSave";
import ButtonCancel from "../../../../Components/ButtonCancel";
import ButtonDelete from "../../../../Components/ButtonDelete";
import HTTP from "../../../../https/HTTP";
import HTTPUtils from "../../../../https/HTTPUtils";
import AppConfig from "../../../../AppConfig";
import { showLoading } from "../../../../Redux/LoadingReducer";
import Map from "../../../../Components/Map";
const { Option } = Select;


function getProvinceName (position) {
  const geocoder = new window.google.maps.Geocoder();
  const { lat, lng } = position;
  return new Promise((resolve, reject) => {
    geocoder.geocode({ 'latLng': { lat, lng } }, function (results, status) {

        if (status === window.google.maps.GeocoderStatus.OK) {
          const prov = (results || []).find(x => x.types.includes('administrative_area_level_1'));
          if (prov) {
            resolve(prov.formatted_address.replace(' ประเทศไทย', '').replace('จังหวัด', ''));
          }
          resolve('');
        } else {
          reject('');
        }
    });
  });
}

const ProjectInfoForm = ({ urlParams, router }) => {
  const [form] = Form.useForm();
  const [provinces, setProvinces] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [types, setTypes] = useState([]);
  // const [project, setProject] = useState({});
  // const stateLoading = useSelector((state) => state.loading);
  const [isDisableTypeInput, setIsDisableTypeInput] = useState(false);
  const [isShowModalSetLocation, setIsShowModalSetLocation] = useState(false);
  const [latLong, setlatLong] = useState();
  const [radius, setRadius] = useState(100);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoading(true));
        await getMasterData();
        await getProject(urlParams.projectId);
        dispatch(showLoading(false));
      } catch {
        //display error
        dispatch(showLoading(false));
      }
    })();
  }, []);

  const getMasterData = async () => {
    try {
      const provinceResponses = await HTTP("GET", "province").execute();
      const truckResponses = await HTTP("GET", "truck").execute();
      const typeResponse = await HTTP("GET", "type").execute();
      let provinces = provinceResponses.data.map((p) => ({
        value: p.id,
        label: p.name,
      }));
      let trucks = truckResponses.data.map((t) => ({
        value: t.id,
        label: t.name,
      }));
      let types = typeResponse.data.map((t) => ({
        value: t.id,
        label: t.name,
      }));
      setProvinces(provinces);
      setTrucks(trucks);
      setTypes(types);
    } catch (ex) {
      console.log(ex);
    }
  };

  const getProject = async (projectId) => {
    const http = HTTP("GET", "project/" + projectId);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      const project = responseToProjectFormData(response.data);
      // setProject(response.data);
      setIsDisableTypeInput(true);
      // @14.9602412,101.0515142,8.18z
      form.setFieldsValue(project);
      if (project.latitude) {
        setlatLong({ lat: project.latitude, long: project.longitude });
      }

      if (project.radius) {
        setRadius(project.radius);
      }
    } else {
      //show error
    }
  };

  const responseToProjectFormData = (response) => {
    let project = {
      name: response.name,
      owner: response.owner,
      address: response.address,
      department: response.department,
      constructionNumber: response.constructionNumber,
      date: moment(response.date),
      dueDate: response.dueDate ? moment(response.dueDate) : "",
      diselB7: response.diselB7,
      gasohol91: response.gasohol91,
      advanceMoney: response.advanceMoney,
      interest: response.interest,
      vat: response.vat,
      insurance: response.insurance,
      truckId1: parseInt(response.truckId1),
      truckId2: parseInt(response.truckId2),
      provinceId: parseInt(response.provinceId),
      provinceNearById1: parseInt(response.provinceNearById1),
      provinceNearById2: parseInt(response.provinceNearById2),
      typeId: parseInt(response.typeId),
      latitude: response.latitude,
      longitude: response.longitude,
      radius: response.radius,
    };

    return project;
  };

  const createProject = async (request) => {
    const http = HTTP("POST", "project/create", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      router.replace(
        // AppConfig.appPath + "/project/select/category"
        AppConfig.appPath + `/project/${response.data.id}/info`
      );
      toast.success("สำเร็จ");
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const updateProject = async (request) => {
    const http = HTTP("POST", "project/update", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("สำเร็จ");
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const duplicateProject = async (request) => {
    const http = HTTP("POST", "project/duplicate", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      router.replace(
        // AppConfig.appPath + "/project/select/category"
        AppConfig.appPath + `/project/${response.data.id}/info`
      );
      toast.success("สำเร็จ");
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const deleteProject = async (request) => {
    const http = HTTP("POST", "project/delete", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      router.replace(AppConfig.appPath + `/home`);
      toast.success("สำเร็จ");
    } else {
      //show error
      toast.error("ล้มเหลว");
    }
  };

  const onFinish = async (values) => {
    dispatch(showLoading(true));
    // const projectId = parseInt(urlParams.projectId);
    // if (isNaN(projectId)) {
    //   const userId = window.sessionStorage?.userId;
    //   if (!userId) {
    //     toast.error("ไม่พบข้อมูลผู้ใช้งานโปรด login ใหม่");
    //     return;
    //   }
    //   const createRequest = { ...values, userId: userId };
    //   await createProject(createRequest);
    // } else {
    //   const updateRequest = { ...values, userId: 1, id: projectId };
    //   await updateProject(updateRequest);
    // }

    dispatch(showLoading(false));
  };

  const onClickSaveButton = async () => {
    form
      .validateFields()
      .then(async (values) => {
        dispatch(showLoading(true));
        const projectId = parseInt(urlParams.projectId);
        if (isNaN(projectId)) {
          const userId = window.sessionStorage?.userId;
          if (!userId) {
            toast.error("ไม่พบข้อมูลผู้ใช้งานโปรด login ใหม่");
            return;
          }
          const createRequest = { ...values, userId: userId, radius: radius };
          await createProject(createRequest);
        } else {
          const updateRequest = {
            ...values,
            userId: 1,
            id: projectId,
            radius: radius,
          };
          await updateProject(updateRequest);
        }

        dispatch(showLoading(false));
      })
      .catch((errorInfo) => {});
  };

  const onClickDuplicateButton = async () => {
    form
      .validateFields()
      .then(async (values) => {
        dispatch(showLoading(true));
        const projectId = parseInt(urlParams.projectId);
        const userId = window.sessionStorage?.userId;
        if (!userId) {
          toast.error("ไม่พบข้อมูลผู้ใช้งานโปรด login ใหม่");
          return;
        }

        const request = { ...values, userId: userId, oldId: projectId };
        await duplicateProject(request);

        dispatch(showLoading(false));
      })
      .catch((errorInfo) => {});
  };

  const onClickDeleteButton = async () => {
    form
      .validateFields()
      .then(async (values) => {
        dispatch(showLoading(true));
        const projectId = parseInt(urlParams.projectId);
        const userId = window.sessionStorage?.userId;
        if (!userId) {
          toast.error("ไม่พบข้อมูลผู้ใช้งานโปรด login ใหม่");
          return;
        }

        const request = { id: projectId, userId: userId };
        await deleteProject(request);
        dispatch(showLoading(false));
      })
      .catch((errorInfo) => {});
  };

  const dismissModalSetLocation = () => {
    setIsShowModalSetLocation(false);
  };

  const onClickSaveLocation = async () => {
    setIsShowModalSetLocation(false);
    // find province
    const provinceName = await getProvinceName({ lat: latLong.lat, lng:  latLong.long });
    console.log('province', provinceName);
    const provinceItem = provinces.find(p => p.label === provinceName);
    let provinceId = undefined;
    if (provinceItem) {
      provinceId = provinceItem.value;
    }
    form.setFieldsValue({ latitude: latLong.lat, longitude: latLong.long, provinceId });
  };

  const onClickMap = (ev) => {
    console.log('click map', ev);
    setlatLong({ lat: ev.latLng.lat(), long: ev.latLng.lng() });
  };

  const onChangeRadius = (value) => {
    setRadius(value);
  };

  const onChangeLat = (value) => {
    setlatLong({ lat: parseFloat(value.target.value), long: latLong.long });
  }

  const onChangeLong = (value) => {
    console.log('value long', value);
    setlatLong({ lat: latLong.lat, long: parseFloat(value.target.value) })
  }

  const openGPSWeb = () => {
    const url = AppConfig.gpsWebPath;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <div>
      <Form
        form={form}
        // onFinish={onFinish}
        // initialValues={{
        //   typeId: "1",
        // }}
        // initialValues={project}
      >
        <div hidden>
          <InputRowProjectInfo
            label=""
            keyInput="latitude"
            InputComponent={<Input placeholder="Latitude"></Input>}
          />
          <InputRowProjectInfo
            label=""
            keyInput="longitude"
            InputComponent={<Input placeholder="longitude"></Input>}
          />
        </div>
        <InputRowProjectInfo
          label="ชื่อโครงการ1"
          keyInput="name"
          InputComponent={<Input placeholder="ชื่อโครงการ"></Input>}
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="ประเภทโครงการ"
          keyInput="typeId"
          InputComponent={
            <Select
              allowClear
              showSearch
              options={types}
              placeholder="ประเภทโครงการ"
              filterOption={(input, option) =>
                option.label.indexOf(input.toLowerCase()) >= 0
              }
              disabled={isDisableTypeInput}
            ></Select>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="เจ้าของโครงการ"
          keyInput="owner"
          InputComponent={<Input placeholder="เจ้าของโครงการ"></Input>}
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="ที่อยู่"
          keyInput="address"
          InputComponent={<Input placeholder="ที่อยู่"></Input>}
          // formColSpan={12}
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
          isDisplaySetLocationButton={true}
          onClickSetLocationButton={() => {
            setIsShowModalSetLocation(true);
          }}
        />

        <InputRowProjectInfo
          label="จังหวัดโครงการ"
          keyInput="provinceId"
          InputComponent={
            <Select
              allowClear
              showSearch
              options={provinces}
              placeholder="จังหวัดโครงการ"
              filterOption={(input, option) =>
                option.label.indexOf(input.toLowerCase()) >= 0
              }
            ></Select>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="จังหวัดข้างเคียง 1"
          keyInput="provinceNearById1"
          InputComponent={
            <Select
              allowClear
              showSearch
              options={provinces}
              placeholder="จังหวัดข้างเคียง 1"
              filterOption={(input, option) =>
                option.label.indexOf(input.toLowerCase()) >= 0
              }
            ></Select>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="จังหวัดข้างเคียง 2"
          keyInput="provinceNearById2"
          InputComponent={
            <Select
              allowClear
              showSearch
              options={provinces}
              placeholder="จังหวัดข้างเคียง 2"
              filterOption={(input, option) =>
                option.label.indexOf(input.toLowerCase()) >= 0
              }
            ></Select>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="หน่วยงาน"
          keyInput="department"
          InputComponent={<Input placeholder="หน่วยงาน"></Input>}
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="แบบเลขที่"
          keyInput="constructionNumber"
          InputComponent={<Input placeholder="แบบเลขที่"></Input>}
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="วันที่ทำการประเมิน"
          keyInput="date"
          InputComponent={
            <DatePicker
              placeholder={"วันที่ทำการประเมิน"}
              style={{ width: "100%", fontSize: "14px", borderRadius: "25px" }} //fontsize icon not relate input
              size="large"
              format="DD/MM/YYYY"
            ></DatePicker>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        {/* <InputRowProjectInfo
        label="วันที่สิ้นสุดการประเมิน"
        keyInput="dueDate"
        InputComponent={
          <DatePicker
            placeholder={"วันที่สิ้นสุดการประเมิน"}
            style={{ width: "100%", fontSize: "14px", borderRadius: "25px" }} //fontsize icon not relate input
            size="large"
            format="DD/MM/YYYY"
          ></DatePicker>
        }
      /> */}
        <InputRowProjectInfo
          label="ราคาน้ำมันดีเซล B7"
          keyInput="diselB7"
          InputComponent={
            <InputNumber
              placeholder="ราคาน้ำมันดีเซล B7"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%", borderRadius: "25px" }}
            />
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="ราคาน้ำมัน Gasohol 91"
          keyInput="gasohol91"
          InputComponent={
            <InputNumber
              placeholder="ราคาน้ำมัน Gasohol 91"
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%", borderRadius: "25px" }}
            />
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="รถขนเหล็ก ท่อระบายน้ำ หินใหญ่ ทรายถม"
          keyInput="truckId1"
          InputComponent={
            <Select
              allowClear
              showSearch
              options={trucks}
              placeholder="รถบรรทุก"
            ></Select>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="รถขนวัสดุอื่นๆ"
          keyInput="truckId2"
          InputComponent={
            <Select
              allowClear
              showSearch
              options={trucks}
              placeholder="รถบรรทุก"
            ></Select>
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="เงินล่วงหน้า"
          keyInput="advanceMoney"
          InputComponent={
            <Input
              type="number"
              placeholder="เงินล่วงหน้า"
              suffix="%"
              style={{ width: "100%", borderRadius: "25px" }}
            />
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="เงินประกันผลงาน"
          keyInput="insurance"
          InputComponent={
            <Input
              type="number"
              placeholder="เงินประกันผลงาน"
              suffix="%"
              style={{ width: "100%", borderRadius: "25px" }}
            />
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="ดอกเบี้ย"
          keyInput="interest"
          InputComponent={
            <Input
              type="number"
              placeholder="ดอกเบี้ย"
              suffix="%"
              style={{ width: "100%", borderRadius: "25px" }}
            />
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        <InputRowProjectInfo
          label="ภาษีมูลค่าเพิ่ม"
          keyInput="vat"
          InputComponent={
            <Input
              type="number"
              placeholder="VAT"
              suffix="%"
              style={{ width: "100%", borderRadius: "25px" }}
            />
          }
          isRequire
          messageRequire="กรุณากรอกข้อมูล"
        />
        {/* <InputRowProjectInfo
        label="ภาพโครงการ"
        keyInput="image"
        InputComponent={<DragImage />}
      /> */}

        {/* <Row style={{ marginTop: "60px", height: "100px" }}>
        <Col xs={22} xl={6}>
          <Form.Item style={{ paddingTop: "50px"}}>
            <ButtonSave label="บันทึก" width="100%"></ButtonSave>
          </Form.Item>
        </Col>
      </Row> */}
      </Form>
      <Row style={{ marginTop: "10px" }}>
        <Col xl={3} style={{ textAlign: "right" }}></Col>
        <Col xs={2} xl={2} style={{ marginLeft: "10px" }}></Col>
        <Col xs={22} xl={4}>
          <ButtonSave
            label="บันทึก"
            width="100%"
            onClick={onClickSaveButton}
          ></ButtonSave>
        </Col>
        <Col xs={2} xl={1}></Col>
        {isNaN(urlParams.projectId) ? null : (
          <Col xs={22} xl={4}>
            <Popconfirm
              title="ยืนยันการคัดลอก"
              onConfirm={onClickDuplicateButton}
              // onCancel={cancel}
              okText="ตกลง"
              cancelText="ยกเลิก"
            >
              <ButtonCancel
                label="คัดลอก"
                width="100%"
                // onClick={handleFormSubmit}
              ></ButtonCancel>
            </Popconfirm>
          </Col>
        )}

        <Col xs={2} xl={1}></Col>
        {/* <Col xs={22} xl={4}>
          <Popconfirm
            title="ยืนยันการลบ"
            onConfirm={onClickDeleteButton}
            // onCancel={cancel}
            okText="ตกลง"
            cancelText="ยกเลิก"
          >
            <ButtonDelete
              label="ลบ"
              width="100%"
              // onClick={handleFormSubmit}
            ></ButtonDelete>
          </Popconfirm>
        </Col> */}
      </Row>
      <Modal
        title={
          <label
            className="font-common"
            style={{ fontSize: "30px", paddingTop: "15px" }}
          >
            {"ตั้งค่าพิกัดโครงการ"}
          </label>
        }
        visible={isShowModalSetLocation}
        onCancel={dismissModalSetLocation}
        footer={null}
        width={1000}
      >
        <Row>
          <Col xl={1}>
            <label className="font-common" style={{ fontSize: "20px" }}>
              {"รัศมี"}
            </label>
          </Col>
          <Col xl={3} xs={3}>
            <Select onChange={onChangeRadius} defaultValue={radius}>
              <Option value={100}>100 ก.ม.</Option>
              <Option value={200}>200 ก.ม.</Option>
              <Option value={300}>300 ก.ม.</Option>
            </Select>
          </Col>
          <Col xl={6} xs={6}>
            <ButtonSave
              label="โปรแกรมระบบ GPS"
              width="100%"
              onClick={openGPSWeb}
            ></ButtonSave>
            
          </Col>
          <Col xs={1}>
            <span style={{lineHeight: '30px', marginLeft: '10px'}}>Lat:</span>
          </Col>
          <Col xs={4}>
            <Input placeholder="Latitude" type="number" value={latLong?.lat} onChange={onChangeLat} step="0.05" style={{ lineHeight: '10px', marginBottom:4}} />
          </Col>
          <Col xs={1}>
            <span style={{lineHeight: '30px', marginLeft: '10px'}}>Lng:</span>
          </Col>
          <Col xs={4}>
            <Input placeholder="Longitude" type="number" value={latLong?.long} onChange={onChangeLong} step="0.05" style={{ lineHeight: '10px', marginBottom:4}} />
          </Col>
        </Row>
        <Row>
          <Col xl={24} xs={24}>
            <Map onClick={onClickMap} latLong={latLong} radius={radius}></Map>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <ButtonSave
            label="บันทึก"
            width="100%"
            onClick={onClickSaveLocation}
          ></ButtonSave>
        </Row>
      </Modal>
    </div>
  );
};

export default ProjectInfoForm;
