import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import NavBar2 from "../../Components/NavBar2";
import UserInfoForm from "./Components/UserInfoForm";
// import AuthHelper from '../../Helper/AuthHelper';
import { Row, Layout, Col } from "antd";
import { Background } from "../../Constants/Images";

const Info = (props) => {
  // const { projectId } =;

  useEffect(() => {
    (async () => {
      try {
        // dispatch(showLoading(false));
      } catch {
        //display error
        // dispatch(showLoading(false));
      }
    })();
  }, []);

  return (
    <div>
      <NavBar2 labelEnglish={"Info"} labelThai={"ข้อมูลผู้ใช้งาน"}></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "60px", paddingTop: "0px" }}
            >
              <UserInfoForm router={props.history} isEdit={true} />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Info;
