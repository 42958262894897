// import AppConfig from "../AppConfig";
import RefreshToken from "./RefreshToken";
import Storage from "./Storage";
import AppConfig from "../AppConfig";
const HTTPUtils = (function () {
  const getHttpHeader = async (key) => {
    var requestHeaders = new Headers();
    try {
      const accessToken = Storage.get(key);
      if (accessToken) {
        requestHeaders.append("Authorization", "Bearer " + accessToken);
      }
    } catch (ex) {
      console.log("cannot get bsRefreshToken from store");
    }

    requestHeaders.append("Content-Type", "application/json");
    // requestHeaders.append("X-Requested-With", "XMLHttpRequest");
    // console.log(requestHeaders);
    return requestHeaders;
  };

  const handleAPIResponse = async (
    baseFunction,
    response,
    request,
    retryCount = 0,
    abortSignal,
    fetchController,
    isFormData
  ) => {
    try {
      // console.log(response);
      // if (AppConfig.showAPIRequestResponseLog) {
      // 	NVConsole.logHttp(request, response);
      // }

      if (isSuccess(response)) {
        return response;
      } else if (isUnauthorized(response)) {
        console.log("unauthroized:");
        await RefreshToken();
        response = await baseFunction(
          request.method,
          request.service,
          request.urlParams,
          request.body,
          0,
          // ++retryCount,
          abortSignal
        );
      }
    } catch (ex) {
      console.log(ex);
      return {
        status: 500,
      };
    }

    return response;
  };

  const buildQueryString = (urlParams) => {
    let paramString = "";
    if (urlParams) {
      paramString = "?";
      for (var key in urlParams) {
        if (urlParams.hasOwnProperty(key)) {
          paramString = paramString + key + "=" + urlParams[key] + "&";
        }
      }

      paramString = paramString.slice(0, -1);
    }

    return paramString;
  };

  const isSuccess = (response) => {
    return response.status >= 200 && response.status < 300;
  };

  const isUnauthorized = (response) => {
    return response.status === 401;
  };

  const isBadRequest = (response) => {
    return response.status === 400;
  };

  const isInternalServerError = (response) => {
    return response.status === 500;
  };

  // const isNotAcceptable = (response) => {
  //   return response.status === 406 && !response.invalidVersion;
  // };

  const isInvalidVersion = (response) => {
    return response.status === 406 && response.invalidVersion;
  };

  const isError = (response) => {
    return (
      response.status !== 200 &&
      response.status !== 401 &&
      response.status !== 406
    );
  };

  return {
    getHttpHeader,
    handleAPIResponse,
    isSuccess,
    isUnauthorized,
    isError,
    isBadRequest,
    isInternalServerError,
    isInvalidVersion,
    buildQueryString,
  };
})();

export default HTTPUtils;
