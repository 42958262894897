import React from "react";
import { Button } from "antd";

const ButtonSave = ({ label, width, height, href, onClick }) => {
  return (
    <Button
      className="font-common"
      style={{
        background: "linear-gradient(#8dfa08, #0d9951)",
        borderRadius: "50px",
        width,
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        borderColor: "white",
        // borderWidth: "4px",
      }}
      htmlType="submit"
      href={href}
      onClick={onClick}
    >
      {/* <label
        style={{ paddingTop: "15px", fontSize: "20px", fontWeight: "bold" }}
      >
        {label}
      </label> */}
      <div style={{ paddingTop: "5px", fontSize: "20px", fontWeight: "bold" }}>
        {label}
      </div>
    </Button>
  );
};

export default ButtonSave;
