const columns = [
    {
      title: 'ลำดับ',
      dataIndex: "no",
      key: "no",
      width: 24,
      align: "center",
    },
    {
      title: "รายการ",
      dataIndex: "name",
      key: "name",
      width: 100,
    },
    {
      title: "คอนกรีต (ลบ.ม.)",
      dataIndex: "concreteCum",
      key: "concreteCum",
      width: 32,
      align: "center"
    },
    {
      title: "ไม้แบบ (ตร.ม.)",
      dataIndex: "woodenModelSqm",
      key: "woodenModelSqm",
      width: 30,
      align: "center"
    },
    {
      title: "ไม้ค้ำยัน (ต้น)",
      dataIndex: "crutchPcs",
      key: "name",
      width: 30,
      align: "center"
    },
    {
      title: "เหล็กเส้นกลมผิวเรียบ/ม.",
      width: 100,
      children: [
        {
          title: "6 มม.",
          dataIndex: "smoothBar6",
          key: "smoothBar6",
          width: 20,
          align: "center"
        },
        {
          title: "9 มม.",
          dataIndex: "smoothBar9",
          key: "smoothBar9",
          width: 20,
          align: "center"
        },
        {
          title: "12 มม.",
          dataIndex: "smoothBar12",
          key: "smoothBar12",
          width: 20,
          align: "center"
        },
        {
          title: "15 มม.",
          dataIndex: "smoothBar15",
          key: "smoothBar15",
          width: 20,
          align: "center"
        },
        {
          title: "19 มม.",
          dataIndex: "smoothBar19",
          key: "smoothBar19",
          width: 20,
          align: "center"
        },
        {
          title: "25 มม.",
          dataIndex: "smoothBar25",
          key: "smoothBar25",
          width: 20,
          align: "center"
        }
      ]
    },
    {
      title: "เหล็กเส้นกลมผิวข้ออ้อย/ม.",
      width: 100,
      children: [
        {
          title: "12 มม.",
          dataIndex: "deformedBar12",
          key: "deformedBar12",
          width: 20,
          align: "center"
        },
        {
          title: "16 มม.",
          dataIndex: "deformedBar16",
          key: "deformedBar16",
          width: 20,
          align: "center"
        },
        {
          title: "20 มม.",
          dataIndex: "deformedBar20",
          key: "deformedBar20",
          width: 20,
          align: "center"
        },
        {
          title: "25 มม.",
          dataIndex: "deformedBar25",
          key: "deformedBar25",
          width: 20,
          align: "center"
        },
        {
          title: "28 มม.",
          dataIndex: "deformedBar28",
          key: "deformedBar28",
          width: 20,
          align: "center"
        }
      ]
    }
  ];

export default columns;