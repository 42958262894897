import React, { Component } from "react";
// import { toast } from 'react-toastify';
import NavBar2 from "../../../Components/NavBar2";
import ProjectInfoFormBK from "./Components/ProjectInfoFormBK";
// import AuthHelper from '../../Helper/AuthHelper';
import "../../../Styles/TabCard.css";
import { Layout } from "antd";
import { Background } from "../../../Constants/Images";

export default class InfoBk extends Component {
  componentDidMount = () => {
    const { projectId } = this.props.match.params;
    console.log("Project ", projectId);
  };

  render() {
    return (
      <div>
        <NavBar2
          labelEnglish={"PROJECT INFO"}
          labelThai={"ข้อมูลโครงการ"}
        ></NavBar2>
        <Layout>
          <Layout.Content
            className="background"
            style={{ backgroundImage: `url("${Background}")` }}
          >
            <ProjectInfoFormBK />
          </Layout.Content>
        </Layout>
      </div>
    );
  }
}
