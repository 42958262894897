import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "../../../../Styles/Upload.css";
import { Row, Upload, Button } from "antd";
import ButtonUpload from "./ButtonUpload";
import AppConfig from "../../../../AppConfig";

const UploadDocument = ({ handleUpload, fileList }) => {
  // const [fileList, setFileList] = useState([]);

  // const handleUpload1 = ({ fileList }) => {
  //   console.log("handle upload");
  //   console.log("fileList", fileList);
  //   setFileList(fileList);
  // };

  // const handlePreview = (file) => {
  //   console.log(file.originFileObj);
  //   DownloadHelper.downloadBlob(file.originFileObj, "reportPR5.png");
  // };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    onSuccess("Ok");
  };

  return (
    <Row>
      <Upload
        fileList={[...fileList]}
        listType="picture"
        // defaultFileList={[...fileList]}
        // onPreview={handlePreview}
        customRequest={uploadImage}
        onChange={handleUpload}
        className="upload-list-inline"
      >
        <ButtonUpload
          label={"อัพโหลดไฟล์"}
          fontSize={"20px"}
          background={"black"}
          // height={"70%"}
          fontColor={"#FF6E00"}
          borderColor={"white"}
          borderWidth={"4px"}
          labelPaddingTop={"15px"}
          width={"150px"}
        ></ButtonUpload>
      </Upload>
    </Row>
  );
};

export default UploadDocument;
