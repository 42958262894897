/** This module is a wrapper for old HTTP functions */

import HTTP from "./HTTP";
import HTTPUtils from "./HTTPUtils";

/**
 * call api (GET)
 * @param {String} url 
 * @param {{}} params 
 * @returns {Promise<{data: {}}>}
 */
async function get (url, params) {
    const http = HTTP("GET", url, params);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
        return response;
    } else {
        throw response;
    }
}
/**
 * call api (POST)
 * @param {String} url 
 * @param {{}} params 
 * @returns {Promise<{data: {}}>}
 */
async function post(url, data) {
    const http = HTTP("POST", url, null, data);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
        return response;
    } else {
        throw response;
    }
}

/**
 * call api (PUT)
 * @param {String} url 
 * @param {{}} params 
 * @returns {Promise<{data: {}}>}
 */
 async function put(url, data) {
    const http = HTTP("PUT", url, null, data);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
        return response;
    } else {
        throw response;
    }
}

/**
 * call api with method
 * @param {'get'|'post'|'put'|'delete'} method
 * @param {String} url 
 * @param {{}} params 
 * @returns {Promise<{data: {}}>}
 */
 async function request(method, url, data) {
    const http = HTTP(method.toUpperCase(), url, null, data);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
        return response;
    } else {
        throw response;
    }
}

const api = {
    get,
    post,
    put,
    request,
}

export default api
