import React from "react";
import "antd/dist/antd.css";
import { Row, Col } from "antd";
import ButtonSave from "../../../../Components/ButtonSave";
import Map from "../../../../Components/Map";

const ProjectAddressForm = () => {
  return (
    <>
      <Row gutter={24}>
        <Col span={8} style={{ marginBottom: "20px" }}>
          <ButtonSave
            onClick={() => {}}
            label="บันทึก"
            width="50%"
          ></ButtonSave>
        </Col>
      </Row>
      <Map isMarkerShown />
    </>
  );
};

export default ProjectAddressForm;
