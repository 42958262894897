import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../Redux/LoadingReducer";
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col, Tree, Modal, Input, Form } from "antd";
import { Background } from "../../../Constants/Images";
import TableSummaryPR4 from "./Components/TableSummaryPR4";
import MenuStep from "../../../Components/MenuStep";
// import { Area, Special } from "../../../Constants/SubWorkGroupId";
import { Building, DevCity } from "../../../Constants/Type";
import AppConfig from "../../../AppConfig";
import {
  columnDam,
  columnBuilding,
  // columnSpecial,
} from "../../../Constants/ColumnFormat";
import HTTP from "../../../https/HTTP";
import HTTPUtils from "../../../https/HTTPUtils";
import DownloadHelper from "../../../Helpers/DownloadHelper";
// import { getType } from "@reduxjs/toolkit";
// const { TabPane } = Tabs;
const SummaryPR4 = (props) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [isBuildingType, setIsBuildingType] = useState(false);
  const [workGroupOptions, setWorkGroupOptions] = useState([]);
  const [selectedWorkGroupId, setSelectedWorkGroupId] = useState();
  useEffect(() => {
    (async () => {
      dispatch(showLoading(true));
      const projectId = props.match.params.projectId;
      // await getSummaryPR4Dam(projectId);
      await getProject(projectId);
      dispatch(showLoading(false));
    })();
  }, []);

  // useEffect(() => {
  //   if (tableData) {
  //   }
  // }, [tableData]);

  const selectedColumnFormat = () => {
    if (isBuildingType) {
      return columnBuilding;
    } else {
      return columnDam;
    }
  };

  const getProject = async (projectId) => {
    const http = HTTP("GET", `project/${projectId}`);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      var typeId = response.data.typeId;
      if (typeId == Building || typeId == DevCity) {
        setIsBuildingType(true);
        var workGroupResponse = await getWorkGroups(projectId);
        var workGroups = workGroupResponse.map((w) => ({
          value: w.id,
          label: w.name,
        }));

        setWorkGroupOptions(workGroups);
        setSelectedWorkGroupId(workGroups[0]?.value);
        await getSummaryPR4Building(projectId, workGroups[0]?.value);
      } else {
        await getSummaryPR4Dam(projectId);
      }
    } else {
      toast.error("ไม่พบข้อมูล");
    }
  };

  const getSummaryPR4Dam = async (projectId) => {
    const http = HTTP(
      "GET",
      `projectItem/summary/project/${projectId}/workgroup/0`
    );
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      setTableData(response.data);
    } else {
      toast.error("ไม่พบข้อมูล");
    }
  };

  const getSummaryPR4Building = async (projectId, workGroupId) => {
    const http = HTTP(
      "GET",
      `projectItem/summary/project/${projectId}/workgroup/${workGroupId}`
    );
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      setTableData(response.data);
    } else {
      toast.error("ไม่พบข้อมูล");
    }
  };

  const getWorkGroups = async (projectId) => {
    const http = HTTP("GET", `workGroup/project/${projectId}`);

    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      return response.data;
    } else {
      toast.error("ไม่พบข้อมูล");
    }
  };

  const downloadSummary = async () => {
    dispatch(showLoading(true));
    const projectId = props.match.params.projectId;
    let url = "";
    if (selectedWorkGroupId) {
      url = `${AppConfig.apiUrl}projectItem/summary/project/${projectId}/workgroup/${selectedWorkGroupId}/download`;
    } else {
      url = `${AppConfig.apiUrl}projectItem/summary/project/${projectId}/workgroup/0/download`;
    }

    const response = await fetch(url, {
      method: "GET",
    });
    if (response.status != 200) {
      toast.error("download ล้มเหลว");
      return;
    }

    response
      .blob()
      .then((blob) => DownloadHelper.downloadBlob(blob, "reportPR4.xlsx"));
    dispatch(showLoading(false));
  };

  const onSelectChange = async (workGroupId) => {
    if (!workGroupId) return;
    dispatch(showLoading(true));
    setSelectedWorkGroupId(workGroupId);
    setTableData([]); // this line prevent old data merge with new data
    const projectId = props.match.params.projectId;
    await getSummaryPR4Building(projectId, workGroupId);
    dispatch(showLoading(false));
  };

  return (
    <div>
      <NavBar2 labelEnglish={"Summary PR4"} labelThai={"สรุป ปร.4"}></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep
                selectedIndex={6}
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "30px", paddingTop: "20px" }}
            >
              <Row>
                <Col
                  xl={24}
                  style={{
                    backgroundColor: "",
                  }}
                >
                  <TableSummaryPR4
                    projectId={props.match.params.projectId}
                    columnFormat={selectedColumnFormat()}
                    data={tableData}
                    onDownLoadClick={downloadSummary}
                    workGroupOptions={workGroupOptions}
                    onSelectChange={onSelectChange}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default SummaryPR4;
