import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import { Button } from "antd";
// import AuthHelper from '../../Helper/AuthHelper';

const ButtonMenuLeftSide = ({ label, href, onClick, buttonProp }) => {
  useEffect(() => {
    // if (buttonProp) {
    //   console.log(
    //     `ButtonMenuLeftSide borderColor = ${buttonProp.borderColor} backgroundColor = ${buttonProp.backgroundColor}`
    //   );
    // }
  }, []);

  return (
    <Button
      className="font-common"
      style={{
        background: buttonProp?.background ? buttonProp.background : "black",
        borderRadius: "10px",
        width: buttonProp?.width ? buttonProp.width : "130px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: buttonProp?.fontColor ? buttonProp.fontColor : "orange",
        borderColor: buttonProp?.borderColor
          ? buttonProp.borderColor
          : "orange",
        borderWidth: "3px",
        marginTop: "15px",
        marginLeft: "30px",
        // whiteSpace: "pre-wrap",
        // pointerEvents: "none",
      }}
      href={href}
      onClick={onClick}
    >
      <label
        style={{paddingTop: "15px", fontSize:  buttonProp?.fontSize ? buttonProp.fontSize : "20px", fontWeight: "bold" }}
      >
        {label}
      </label>
    </Button>
  );
};

export default ButtonMenuLeftSide;
