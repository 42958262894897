import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../Redux/LoadingReducer";
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col, Modal, Input, Form } from "antd";
import { Background } from "../../../Constants/Images";
import TableItem from "./Components/TableItem";
import MenuStep from "../../../Components/MenuStep";

import api from "../../../https/api";

const PR2 = (props) => {
  const dispatch = useDispatch();

  const [formWorkGroup] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);


  const projectId = props.match.params.projectId;

  useEffect(() => {

  }, []);

  return (
    <div>
      <NavBar2 labelEnglish={"PR2"} labelThai={"ปร.2"}></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep

                selectedIndex={2}
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "30px", paddingRight: "20px", paddingTop: "20px" }}
            >
              <TableItem
                projectId={projectId}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default PR2;
