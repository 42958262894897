import React, { Component } from "react";
import { Route } from "react-router-dom";
import Info from "../Pages/User/Info";
import Register from "../Pages/User/Register";
import AppConfig from "../AppConfig";
export default class UserRoutes extends Component {
  render() {
    return (
      <span>
        <Route
          exact
          path={AppConfig.appPath + "/user/create"}
          component={Register}
        />
        <Route exact path={AppConfig.appPath + "/user/info"} component={Info} />
      </span>
    );
  }
}
