import React from "react";
import "antd/dist/antd.css";
import "../../../../Styles/Table.css";
import {
  Col,
  Row,
  Table,
  Select,
} from "antd";
// import { DeleteOutlined } from "@ant-design/icons";
import ButtonSave from "../../../../Components/ButtonSave";

const TableSummaryPR4 = ({
  selectedGroupProp,
  projectId,
  columnFormat,
  data,
  onDownLoadClick,
  workGroupOptions,
  onSelectChange,
}) => {
  return (
    <div>
      <Table
        columns={columnFormat}
        dataSource={data}
        bordered
        // rowSelection={{ ...rowSelection, checkStrictly: false }}
        // rowClassName={(_r, i) => (i % 2 ? "odd" : "even")}
        pagination={{
          defaultPageSize: 5000,
          // showSizeChanger: true,
          // pageSizeOptions: ["30", "50", "70", "100"],
        }}
        rowKey={Math.random}
        scroll={{ y: 440, x: 100 }}
        title={() => (
          <div>
            <Row>
              <Col xl={3}>
                <ButtonSave
                  label={"Download"}
                  onClick={onDownLoadClick}
                  width={"150px"}
                ></ButtonSave>
              </Col>
              {workGroupOptions?.length > 0 ? (
                <Col xl={4} style={{ marginLeft: "20px" }}>
                  <Select
                    style={{ width: "100%" }}
                    allowClear
                    showSearch
                    options={workGroupOptions}
                    filterOption={(input, option) =>
                      option.label.indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={onSelectChange}
                    placeholder={"กลุ่มงาน"}
                  ></Select>
                </Col>
              ) : null}
            </Row>
          </div>
        )}
      />
    </div>
  );
};

export default TableSummaryPR4;
