import AppConfig from "../AppConfig";

const Storage = (function () {
  const prefix = AppConfig["storeKeyPrefix"];

  const get = (key) => {
    try {
      return localStorage.getItem(`${prefix}${key}`);
    } catch (ex) {
      console.log("cannot get data from localStorage", ex);
      return undefined;
    }
  };

  const set = (key, value) => {
    try {
      return localStorage.setItem(`${prefix}${key}`, value);
    } catch (ex) {
      console.log("cannot save data to localStorage", ex);
    }
  };

  const clear = (key) => {
    try {
      return localStorage.removeItem(`${prefix}${key}`);
    } catch (ex) {
      console.log("cannot remove data from localStorage", ex);
    }
  };

  const clearAll = () => {
    localStorage.clear();
  };

  return { get, set, clear, clearAll };
})();

export default Storage;
