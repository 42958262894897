import React, { useEffect, useState } from "react";
import HTTP from "../../https/HTTP";
import HTTPUtils from "../../https/HTTPUtils";
import { useDispatch } from "react-redux";
import { showLoading } from "../../Redux/LoadingReducer";
import { toast } from "react-toastify";
import NavBar2 from "../../Components/NavBar2";
import TableHome from "./Components/TableHome";
import SearchBar from "../../Components/SearchBar";
import ButtonRound from "../../Components/ButtonRound";
// import AuthHelper from '../../Helper/AuthHelper';
import AppConfig from "../../AppConfig";
import { Row, Layout, Col, Tabs } from "antd";
// import moment from "moment";
import { Background } from "../../Constants/Images";
import "../../Styles/TabCard.css";

const { TabPane } = Tabs;
const Home = (props) => {
  const dispatch = useDispatch();
  const [projectListInProgress, setProjectListInProgress] = useState([]);

  useEffect(() => {
    (async () => {
      // if (!window.sessionStorage?.userId) {
      //   toast.warn("กรุณา login ก่อนใช้งาน");
      //   props.history.replace(AppConfig.appPath);
      // }

      const projectInProgress = await getProjectList({
        statusId: 1,
        typeId: 0,
      });
      setProjectListInProgress(projectInProgress);
    })();
  }, []);

  const getProjectList = async (searchParam) => {
    dispatch(showLoading(true));
    const http = HTTP("GET", "project", searchParam);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      console.log(response.data);
      dispatch(showLoading(false));
      return response.data;
    } else {
      toast.error("ติดต่อ server ไม่ได้");
    }
    dispatch(showLoading(false));
  };

  return (
    <div>
      <NavBar2 labelEnglish="Home" labelThai="หน้าแรก"></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row style={{ marginTop: "10px" }}>
            <Col xl={18}></Col>
            <Col xl={4}>
              <ButtonRound
                label={"สร้างโครงการ"}
                fontSize={"20px"}
                background={"linear-gradient(#8dfa08, #0d9951)"}
                // height={"70%"}
                fontColor={"white"}
                borderColor={"white"}
                borderWidth={"4px"}
                labelPaddingTop={"15px"}
                // form={form}
                width={"100%"}
                onClick={() => {
                  props.history.replace(
                    // AppConfig.appPath + "/project/select/category"
                    AppConfig.appPath + "/project/new/info"
                  );
                }}
              ></ButtonRound>
            </Col>
            <Col xl={2}></Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col xl={4}></Col>
            <Col xl={18}>
              <TableHome router={props.history} data={projectListInProgress} />
              {/* <div className="card-container">
                <Tabs defaultActiveKey="tab1" size="large" type="card">
                  <TabPane
                    tab={
                      <label
                        className="font-common"
                        style={{ fontSize: "20px" }}
                      >
                        ดำเนินการ
                      </label>
                    }
                    key="tab1"
                  >
                    <TableHome
                      router={props.history}
                      data={projectListInProgress}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <label
                        className="font-common"
                        style={{ fontSize: "20px" }}
                      >
                        เสร็จสิ้น
                      </label>
                    }
                    key="tab2"
                  >
                    <TableHome router={props.history} />
                  </TabPane>
                </Tabs>
              </div>
            */}
            </Col>

            <Col xl={2}></Col>
          </Row>
          <div style={{ marginTop: "10px" }}>
            <SearchBar></SearchBar>
          </div>
        </Layout.Content>
        {/* <Layout.Footer style={{ textAlign: "center" }}>
          - ระบบประมาณราคาภาครัฐ by CCW -
        </Layout.Footer> */}
      </Layout>
    </div>
  );
};

export default Home;
