import React from "react";
import "antd/dist/antd.css";
import { Form, Row, Col } from "antd";
import CrossLine from "../../../../Components/CrossLine";
import ButtonSave from "../../../../Components/ButtonSave";

const InputRowProjectInfo = ({
  label,
  InputComponent,
  keyInput,
  isRequire,
  messageRequire,
  isDisplaySetLocationButton,
  onClickSetLocationButton,
}) => {
  return (
    <Row style={{ height: "60px" }}>
      <Col xl={3} style={{ textAlign: "right", backgroundColor: "" }}>
        <label
          className="font-common size-common"
          style={{
            paddingTop: "20px",
            fontWeight: "bold",
            paddingRight: "10px",
            width: "160px",
          }}
        >
          {label}
        </label>
      </Col>
      <Col xs={2} xl={2} style={{ marginLeft: "10px", backgroundColor: "" }}>
        <CrossLine />
      </Col>
      <Col
        xs={10}
        xl={isDisplaySetLocationButton ? 4 : 4}
        md={5}
        style={{ marginTop: "14px", backgroundColor: "" }}
      >
        <Form.Item
          rules={[
            {
              required: isRequire,
              message: messageRequire,
            },
          ]}
          name={keyInput}
        >
          {InputComponent}
        </Form.Item>
      </Col>
      {isDisplaySetLocationButton ? (
        <Col xl={4} style={{ marginTop: "14px", marginLeft: "10px" }}>
          <ButtonSave
            label={"ตั้งค่าพิกัดโครงการ"}
            onClick={onClickSetLocationButton}
          ></ButtonSave>
        </Col>
      ) : null}
    </Row>
  );
};

export default InputRowProjectInfo;
