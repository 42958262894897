export const columnBuilding = [
  {
    title: "ลำดับที่",
    dataIndex: "number",
    width: 140,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 300,
    align: "left",
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    width: 100,
    align: "center",
  },
  {
    title: "หน่วย",
    dataIndex: "unit",
    width: 75,
    align: "center",
  },
  {
    title: "ราคาวัสดุ",
    dataIndex: "material",
    // width: 150,
    align: "center",
    children: [
      {
        title: "ราคาหน่วยละ",
        dataIndex: "price",
        width: 140,
        align: "center",
      },
      {
        title: "จำนวนเงิน",
        dataIndex: "totalPrice",
        width: 150,
        align: "center",
      },
    ],
  },
  {
    title: "ค่าแรงงาน",
    dataIndex: "labour",
    // width: 150,
    align: "center",
    children: [
      {
        title: "ราคาหน่วยละ",
        dataIndex: "wage",
        width: 140,
        align: "center",
      },
      {
        title: "จำนวนเงิน",
        dataIndex: "totalWage",
        width: 150,
        align: "center",
      },
    ],
  },
  {
    title: "รวมค่าวัสดุและค่าแรงงาน",
    dataIndex: "finalPrice",
    width: 200,
    align: "center",
  },
];
export const columnDam = [
  {
    title: "ลำดับที่",
    dataIndex: "number",
    width: 140,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 300,
    align: "left",
  },
  {
    title: "ปริมาณ",
    dataIndex: "amount",
    width: 100,
    align: "center",
  },
  {
    title: "หน่วย",
    dataIndex: "unit",
    width: 100,
    align: "center",
  },
  {
    title: "ค่างานต้นทุน ต่อหน่วย (บาท)",
    dataIndex: "price",
    width: 120,
    align: "center",
  },
  {
    title: "ค่างานต้นทุนรวม (บาท)",
    dataIndex: "totalPrice",
    width: 150,
    align: "center",
  },

  {
    title: "ค่า Factor F",
    dataIndex: "factorF",
    width: 120,
    align: "center",
  },
  {
    title: "ราคากลาง",
    dataIndex: "",
    // width: 150,
    align: "center",
    children: [
      {
        title: "ราคากลาง      ต่อหน่วย (บาท)",
        dataIndex: "priceFactorF",
        width: 100,
        align: "center",
      },
      {
        title: "ราคากลางรวม (บาท) ",
        dataIndex: "finalPrice",
        width: 150,
        align: "center",
      },
    ],
  },
];
export const columnSpecial = [
  {
    title: "ลำดับที่",
    dataIndex: "number",
    width: 140,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 300,
    align: "left",
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    width: 100,
    align: "center",
  },
  {
    title: "หน่วย",
    dataIndex: "unit",
    width: 75,
    align: "center",
  },
  {
    title: "ค่าใช้จ่ายต่อหน่วย",
    dataIndex: "price",
    width: 140,
    align: "center",
  },
  {
    title: "ค่าใช้จ่ายรวม",
    dataIndex: "totalPrice",
    width: 150,
    align: "center",
  },
];
export const columnBuildingBK = [
  {
    title: "ลำดับ",
    dataIndex: "number",
    width: 320,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 400,
    align: "left",
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    width: 70,
    align: "center",
  },
  {
    title: "หน่วย",
    dataIndex: "unit",
    width: 50,
    align: "center",
  },
  {
    title: "วัสดุ",
    dataIndex: "material",
    // width: 150,
    align: "center",
    children: [
      {
        title: "ราคาต่อหน่วย",
        dataIndex: "price",
        width: 100,
        align: "center",
      },
      {
        title: "รวม",
        dataIndex: "totalPrice",
        width: 150,
        align: "center",
      },
    ],
  },
  {
    title: "ค่าแรง",
    dataIndex: "labour",
    // width: 150,
    align: "center",
    children: [
      {
        title: "ราคาต่อหน่วย",
        dataIndex: "wage",
        width: 100,
        align: "center",
      },
      {
        title: "รวม",
        dataIndex: "totalWage",
        width: 150,
        align: "center",
      },
    ],
  },
  {
    title: "รวมทั้งหมด",
    dataIndex: "finalPrice",
    width: 200,
    align: "center",
  },
];

export const columnDamBk = [
  {
    title: "ลำดับ",
    dataIndex: "number",
    width: 320,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 400,
    align: "left",
  },
  {
    title: "จำนวน",
    dataIndex: "amount",
    width: 70,
    align: "center",
  },
  {
    title: "หน่วย",
    dataIndex: "unit",
    width: 55,
    align: "center",
  },
  {
    title: " ค่างานต้นทุนต่อหน่วย",
    dataIndex: "material",
    // width: 150,
    align: "center",
    children: [
      {
        title: "ราคาต่อหน่วย",
        dataIndex: "price",
        width: 100,
        align: "center",
      },
      {
        title: "รวม",
        dataIndex: "totalPrice",
        width: 150,
        align: "center",
      },
    ],
  },
  {
    title: "factorf",
    dataIndex: "factorF",
    width: 60,
    align: "center",
  },
  {
    title: "รวมทั้งหมด",
    dataIndex: "finalPrice",
    width: 200,
    align: "center",
  },
];

export const columnBlank = [
  {
    title: "",
    dataIndex: "",
    width: 100,
    align: "center",
  },
];

export const columnPR5Dam = [
  {
    title: "ลำดับ",
    dataIndex: "number",
    width: 70,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 400,
    align: "center",
  },
  {
    title: "รวมเป็นเงิน (บาท)",
    dataIndex: "totalFinalPriceString",
    width: 200,
    align: "center",
  },
];

export const columnPR6Dam = [
  {
    title: "ลำดับ",
    dataIndex: "number",
    width: 70,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 400,
    align: "center",
  },
  {
    title: "รวมเป็นเงิน (บาท)",
    dataIndex: "totalFinalPriceString",
    width: 200,
    align: "center",
  },
];

export const columnPR5Building = [
  {
    title: "ลำดับ",
    dataIndex: "number",
    width: 70,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 400,
    align: "center",
  },
  {
    title: "ค่าวัสดุและค่าแรงงาน (บาท)",
    dataIndex: "totalPriceAndWageString",
    width: 400,
    align: "center",
  },
  {
    title: "FACTOR F",
    dataIndex: "factorF",
    width: 100,
    align: "center",
  },
  {
    title: "รวมเป็นเงิน (บาท)",
    dataIndex: "totalFinalPriceString",
    width: 200,
    align: "center",
  },
];

export const columnPR6Building = [
  {
    title: "ลำดับ",
    dataIndex: "number",
    width: 70,
    align: "center",
  },
  {
    title: "รายการ",
    dataIndex: "name",
    width: 400,
    align: "center",
  },
  {
    title: "ค่าวัสดุและค่าแรงงาน (บาท)",
    dataIndex: "totalPriceAndWageString",
    width: 400,
    align: "center",
  },
  {
    title: "FACTOR F",
    dataIndex: "factorF",
    width: 100,
    align: "center",
  },
  {
    title: "รวมเป็นเงิน (บาท)",
    dataIndex: "totalFinalPriceString",
    width: 200,
    align: "center",
  },
];
