import React, { useState, useEffect } from "react";
import "../../../Styles/Divider.css";
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col, Divider } from "antd";
import { Background } from "../../../Constants/Images";
import MenuStep from "../../../Components/MenuStep";
import FormConceteCost from "./Components/FormConceteCost";
import HTTP from "../../../https/HTTP";
import HTTPUtils from "../../../https/HTTPUtils";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../Redux/LoadingReducer";

const ConceteCost = (props) => {
  const dispatch = useDispatch();
  const [responseApi, setResponseApi] = useState();
  useEffect(() => {
    (async () => {
      dispatch(showLoading(true));
      await getConceteAndWoodCost(props.match.params.projectId);
      dispatch(showLoading(false));
      // console.log("p id ", projectId);
      // if (parseInt(projectId)) {
      //   console.log("get project info");
      //   await getProject(projectId);
      // }
      // await getProvince();
    })();
  }, []);

  const getConceteAndWoodCost = async (projectId) => {
    const http = HTTP("GET", `project/${projectId}/concetecost`);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      setResponseApi(response.data);
    } else {
      //show error
    }
  };

  return (
    <div>
      <NavBar2
        labelEnglish={"ค่างานต้นทุนคอนกรีต"}
        labelThai={"Concete Cost"}
      ></NavBar2>

      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep
                // selectedIndex={1}
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "60px", paddingTop: "20px" }}
            >
              <FormConceteCost
                data={responseApi}
                projectId={props.match.params.projectId}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default ConceteCost;
