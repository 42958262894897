import React from "react";
import { Col, Row } from "antd";

const NavbarPageLabel = ({ labelThai, labelEnglish }) => {
  return (
    <Row
      style={{
        backgroundColor: "black",
        width: "100%",
        height: "50px",
        borderRadius: "50px",
        padding: "3px",
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: "2px",
      }}
      justify={"end"}
    >
      <Col span={12}>
        <label
          className="font-common"
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "20px",
            color: "white",
          }}
        >
          {labelEnglish}
        </label>
      </Col>
      <Col span={12}>
        <label
          className="font-common"
          style={{
            background: "linear-gradient(#ff2c00, #ff9100)",
            borderRadius: "50px",
            width: "100%",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {labelThai}
        </label>
      </Col>
    </Row>
  );
};

export default NavbarPageLabel;
