import React from "react";
import "antd/dist/antd.css";
import { Row, Col } from "antd";
import VerticalLine from "../../../../Components/VerticalLine";
const VerticalLineProjectInfo = ({
  label,
  childComponent,
  keyInput,
  isRequire,
  messageRequire,
}) => {
  return (
    <Row style={{ height: "60px" }}>
      <Col xl={3} style={{ textAlign: "right", backgroundColor: "" }}></Col>
      <Col xs={2} xl={2} style={{ marginLeft: "-10px", backgroundColor: "" }}>
        <VerticalLine />
      </Col>
      <Col
        xs={22}
        xl={4}
        md={5}
        style={{ marginTop: "14px", backgroundColor: "" }}
      >
        {childComponent}
      </Col>
    </Row>
  );
};

export default VerticalLineProjectInfo;
