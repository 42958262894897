import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "antd/dist/antd.css";
import { Form, Row, Col, Button } from "antd";
import InputForm from "./InputForm";
import ButtonSave from "../../../../Components/ButtonSave";
import HTTP from "../../../../https/HTTP";
import HTTPUtils from "../../../../https/HTTPUtils";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../../Redux/LoadingReducer";

const FormConeteCost = ({ data, projectId }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useEffect(() => {
    (async () => {})();
    if (data && data?.conceteCosts.length === 7) {
      form.setFieldsValue({
        concete1: data?.conceteCosts[0],
        concete2: data?.conceteCosts[1],
        concete3: data?.conceteCosts[2],
        concete4: data?.conceteCosts[3],
        concete5: data?.conceteCosts[4],
        concete6: data?.conceteCosts[5],
        concete7: data?.conceteCosts[6],
        wood1: data?.woodCosts[0],
        wood2: data?.woodCosts[1],
        wood3: data?.woodCosts[2],
        wood4: data?.woodCosts[3],
        wood5: data?.woodCosts[4],
        wood6: data?.woodCosts[5],
        wood7: data?.woodCosts[6],
      });
    }
  }, [data]);

  const onFinish = async (values) => {
    // console.log("Received values of form: ", values);
    var request = {
      projectId: projectId,
      conceteCosts: [
        values.concete1,
        values.concete2,
        values.concete3,
        values.concete4,
        values.concete5,
        values.concete6,
        values.concete7,
      ],
      woodCosts: [
        values.wood1,
        values.wood2,
        values.wood3,
        values.wood4,
        values.wood5,
        values.wood6,
        values.wood7,
      ],
    };
    dispatch(showLoading(true));
    await createOrUpdateConceteAndWoodCost(request);
    dispatch(showLoading(false));
  };

  const createOrUpdateConceteAndWoodCost = async (request) => {
    const http = HTTP(
      "POST",
      `project/createOrUpdate/concetecost`,
      null,
      request
    );
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("บันทึกสำเร็จ");
    } else {
      //show error
      toast.error("บันทึกล้มเหลว");
    }
  };

  // const layout = {
  //   labelCol: {
  //     span: 4,
  //   },
  //   wrapperCol: {
  //     span: 24,
  //   },
  // };

  return (
    <Form form={form} onFinish={onFinish}>
      <Col span={24}>
        <InputForm
          title={"งานคอนกรีตโครงสร้าง (บ่อพักท่อเหลี่ยม)"}
          conceteName={"concete1"}
          woodName={"wood1"}
        ></InputForm>
        <InputForm
          title={
            "งงานคอนกรีตโครงสร้าง (บ่อพักท่อกลม , บ่อพักน้ำชนิดท่อเหลี่ยม แบบที่ 1 และ แบบที่ 2)"
          }
          conceteName={"concete2"}
          woodName={"wood2"}
        ></InputForm>
        <InputForm
          title={"งานคอนกรีตโครงสร้าง (บ่อสูบน้ำและสถานีสูบน้ำ)"}
          conceteName={"concete3"}
          woodName={"wood3"}
        ></InputForm>
        <InputForm
          title={"งานคอนกรีตโครงสร้าง (รางระบายน้ำรูปตัวยู คสล.)"}
          conceteName={"concete4"}
          woodName={"wood4"}
        ></InputForm>
        <InputForm
          title={"งานคอนกรีตโครงสร้าง (ท่อระบายน้ำ คสล. ชนิดหล่อในที่)"}
          conceteName={"concete5"}
          woodName={"wood5"}
        ></InputForm>
        <InputForm
          title={"งานคอนกรีตโครงสร้าง (กำแพง คสล.)"}
          conceteName={"concete6"}
          woodName={"wood6"}
        ></InputForm>
        <InputForm
          title={"งานคอนกรีตโครงสร้าง (งานเขื่อน)"}
          conceteName={"concete7"}
          woodName={"wood7"}
        ></InputForm>
        <Row>
          <Col xl={4}>
            <ButtonSave label={"บันทึก"} width="200px"></ButtonSave>
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default FormConeteCost;
