import React, { useState, useEffect } from 'react';
import { Modal, Skeleton, Input } from 'antd';
import ButtonSave from '../../../../Components/ButtonSave';
import { UserOutlined } from '@ant-design/icons'
import HTTP from "../../../../https/HTTP";
import TableProjectUser from "../../../../Components/TableProjectUser";

const Component = ({
  projectId,
  projectName,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableFiltered, setDataTableFiltered] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userFilter, setUserFilter] = useState('');

  const showModal = async () => {
    setIsModalVisible(true);
    setLoading(true);
    const http = HTTP('get', `project/${projectId}/users`);
    const result = await http.execute();
    setDataTable(result.data.map(x => ({...x, key: x.id })))
    setDataTableFiltered(result.data);
    setUserFilter('');
    setLoading(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    (async () => {
      await Promise.resolve(0);
    })();
  }, []);

  
  async function setProjectUser() {
    const http = HTTP('post', `project/setUser`, null, {
      projectId,
      permissions:
        dataTable.filter(d => d.selected)
        .map(d => d.subWorkGroupPermissions.filter(sw => sw.selected)
          .map(sw => 
            (
              {
                userId: d.id,
                subWorkGroupId: sw.id,
              }
            )
          )
        )
        .reduce((prev, cur) => [...prev, ...cur], []),
    });
    try {
      await http.execute();
      setIsModalVisible(false);
    } catch {

    }
  }

  


  // useEffect(() => {
  //   setUserFilter('');
  // }, [dataTable])

  useEffect(() => {
    if (!userFilter) {
      setDataTableFiltered([...dataTable]);
    } else {
      const needle = String(userFilter || '').trim().toLowerCase();
      const filtered = dataTable.filter(x => 
        `${x.firstName || ''}${x.lastName || ''}${x.department || ''}${x.occupation || ''}${x.email || ''}`
        .toLowerCase().includes(needle)
      );
      setDataTableFiltered([...filtered]);
    }
    console.log('dataTable', dataTable);
  }, [userFilter, dataTable]);

  return (
    <>
      <UserOutlined style={{cursor: 'pointer'}} onClick={showModal} />
      <Modal
        title="User"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width="1050px"
        footer={[
          <ButtonSave width="100%" label="บันทึก" style={{float: 'right'}} onClick={setProjectUser}></ButtonSave>
        ]
        }
      >
        <div style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0px'}}>
          <div>ชื่อโครงการ: { projectName }</div>
          <div><Input placeholder="ค้นหา" onChange={(e) => setUserFilter(e.target.value)} value={userFilter} style={{ width: 200, height: '30px' }} /></div>

        </div>
        <Skeleton loading={loading}>
          <TableProjectUser key={projectId} canSelect={true} datasource={dataTableFiltered} setDataTable={setDataTable} />
        </Skeleton>
      </Modal>
    </>
  );
};

export default Component;