import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import LoadingReducer from "./LoadingReducer";
import UserReducer from "./UserReducer";
export default configureStore({
  reducer: {
    counter: counterReducer,
    loading: LoadingReducer,
    user: UserReducer,
  },
});
