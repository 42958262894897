import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import AppConfig from "../AppConfig";
import VerticalLine from "./VerticalLine";
import HomeButton from "./HomeButton";
import MenuStepRow from "./MenuStepRow";
const MenuStep = ({ urlParams, selectedIndex }) => {
  const [buttonProps, setButtonprops] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);

  const buttonInActive = {
    borderColor: "orange",
    background: "black",
    fontColor: "orange",
  };

  const buttonActive = {
    borderColor: "orange",
    background: "linear-gradient(#f07725, #ff4200)",
    fontColor: "black",
  };

  const rowHeigth = "47px";
  const paddingLeftLine = "13px";
  const circleColor = "black";
  useEffect(() => {
    changeActiveButton(selectedIndex);
  }, []);

  const changeActiveButton = (selectedIndex) => {
    const buttonNewProps = buttonProps.map((buttonProp, index) => {
      if (selectedIndex == index) {
        return (buttonProp = buttonActive);
      }
      return (buttonProp = buttonInActive);
    });
    // console.log(buttonNewProps);
    setButtonprops(buttonNewProps);
  };

  return (
    <>
      <MenuStepRow
        label="ข้อมูล"
        buttonStyle={buttonProps[0]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/info`}
      />
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>

      <Row style={{ height: rowHeigth }}>
        <Col xs={9} xl={18} style={{ backgroundColor: "" }}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <HomeButton />
        </Col>
      </Row>
    </>
  );
};

export default MenuStep;
