import React, { useEffect } from "react";
// import { toast } from 'react-toastify';
import NavBar2 from "../../../Components/NavBar2";
import ProjectInfoForm from "./Components/ProjectInfoForm";
// import AuthHelper from '../../Helper/AuthHelper';
import { Row, Layout, Col } from "antd";
import { Background } from "../../../Constants/Images";
import MenuStep from "../../../Components/MenuStep";
import MenuStepOnlyInfo from "../../../Components/MenuStepOnlyInfo";
const Info = (props) => {
  // const { projectId } =;

  useEffect(() => {
    (async () => {
      // console.log("p id ", projectId);
      // if (parseInt(projectId)) {
      //   console.log("get project info");
      //   await getProject(projectId);
      // }
      // await getProvince();
    })();
  }, []);

  return (
    <div>
      <NavBar2
        labelEnglish={"PROJECT INFO"}
        labelThai={"ข้อมูลโครงการ"}
      ></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              {isNaN(props.match.params.projectId) ? (
                <MenuStepOnlyInfo
                  selectedIndex={0}
                  urlParams={props.match.params}
                ></MenuStepOnlyInfo>
              ) : (
                <MenuStep
                  selectedIndex={0}
                  urlParams={props.match.params}
                ></MenuStep>
              )}
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "60px", paddingTop: "0px" }}
            >
              <ProjectInfoForm
                urlParams={props.match.params}
                router={props.history}
              />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Info;
