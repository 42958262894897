import React from "react";
// import { toast } from 'react-toastify';
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col } from "antd";
import { Background } from "../../../Constants/Images";
import MenuStep from "../../../Components/MenuStep";
import ProjectAddressForm from "./Components/ProjectAddressForm";

const Location = (props) => {
  return (
    <div>
      <NavBar2 labelEnglish={"พิกัด"} labelThai={"LOCATION"}></NavBar2>

      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep
                selectedIndex={1}
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "60px", paddingTop: "20px" }}
            >
              <ProjectAddressForm />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Location;
