import React, { Component } from "react";
// import { toast } from 'react-toastify';
import NavBar2 from "../../../Components/NavBar2";
// import ProjectInfoForm from "../../../Components/ProjectInfoForm";
// import PicturesWall from "../../../Components/PicturesWall";
// import AuthHelper from '../../Helper/AuthHelper';
import "../../../Styles/TabCard.css";
import AppConfig from "../../../AppConfig";
import { Row, Image, Layout, Col } from "antd";
import ButtonRound from "../../../Components/ButtonRound";
import {
  Building,
  WaterSystem,
  DevelopCity,
  Dam,
  Background,
} from "../../../Constants/Images";
export default class Category extends Component {
  componentDidMount = () => {};

  render() {
    return (
      <div>
        <NavBar2
          labelEnglish={"CATEGORY"}
          labelThai={"ประเภทโครงการ"}
        ></NavBar2>
        <Layout>
          <Layout.Content
            className="background"
            style={{ backgroundImage: `url("${Background}")` }}
          >
            <Row
              style={{
                marginTop: "20px",
              }}
              justify="space-between"
            >
              <Col xs={0} xl={2}></Col>
              <Col xs={12} xl={4}>
                <ButtonRound
                  label="อาคาร"
                  background="black"
                  fontSize="20px"
                  labelPaddingTop="15px"
                  fontColor="#E54901"
                  href={AppConfig.appPath + "/project/1"} //TODO should send api create project
                ></ButtonRound>
                <Image src={Building} preview={false}></Image>
              </Col>
              <Col xs={12} xl={4}>
                <ButtonRound
                  label="ระบบป้องกันระบายน้ำท่วม"
                  background="black"
                  fontSize="20px"
                  labelPaddingTop="15px"
                  fontColor="#E54901"
                  href={AppConfig.appPath + "/project/1"} //TODO should send api create project
                ></ButtonRound>
                <Image src={WaterSystem} preview={false}></Image>
              </Col>
              <Col xs={12} xl={4}>
                <ButtonRound
                  label="เขื่อนป้องกันตลิ่ง"
                  background="black"
                  fontSize="20px"
                  labelPaddingTop="15px"
                  fontColor="#E54901"
                  href={AppConfig.appPath + "/project/1"} //TODO should send api create project
                ></ButtonRound>
                <Image src={Dam} preview={false}></Image>
              </Col>
              <Col xs={12} xl={4}>
                <ButtonRound
                  label="งานพัฒนาเมือง"
                  background="black"
                  fontSize="20px"
                  labelPaddingTop="15px"
                  fontColor="#E54901"
                  href={AppConfig.appPath + "/project/1"} //TODO should send api create project
                ></ButtonRound>
                <Image src={DevelopCity} preview={false}></Image>
              </Col>
              <Col xs={0} xl={2}></Col>
            </Row>
          </Layout.Content>
          {/* <Layout.Footer style={{ textAlign: "center" }}>
            - ระบบประมาณราคาภาครัฐ by CCW -
          </Layout.Footer> */}
        </Layout>
      </div>
    );
  }
}
