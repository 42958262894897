import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import AppConfig from "../AppConfig";
import VerticalLine from "./VerticalLine";
import HomeButton from "./HomeButton";
import MenuStepRow from "./MenuStepRow";
import { showLoading } from "../Redux/LoadingReducer";
import { useDispatch } from "react-redux";
import DownloadHelper from "../Helpers/DownloadHelper";
import { toast } from "react-toastify";
import SecureKey from "../https/SecureKey";
import Storage from "../https/Storage";

const MenuStep = ({ urlParams, selectedIndex }) => {
  const [buttonProps, setButtonprops] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);

  const dispatch = useDispatch();

  const buttonInActive = {
    borderColor: "orange",
    background: "black",
    fontColor: "orange",
  };

  const buttonActive = {
    borderColor: "orange",
    background: "linear-gradient(#f07725, #ff4200)",
    fontColor: "black",
  };

  const rowHeigth = "47px";
  const paddingLeftLine = "13px";
  const circleColor = "black";
  useEffect(() => {
    changeActiveButton(selectedIndex);
  }, []);

  const changeActiveButton = (selectedIndex) => {
    const buttonNewProps = buttonProps.map((buttonProp, index) => {
      if (selectedIndex == index) {
        return (buttonProp = buttonActive);
      }
      return (buttonProp = buttonInActive);
    });
    // console.log(buttonNewProps);
    setButtonprops(buttonNewProps);
  };


  const downloadALLPR = async () => {
      dispatch(showLoading(true));
      try {
        const url = `${AppConfig.apiUrl}projectItem/summary/pr456/project/${urlParams?.projectId}/download`;
        const response = await fetch(url);
    
        if (response.status !== 200) {
          toast.error("download ล้มเหลว");
          return;
        }
        await response
          .blob()
          .then((blob) => DownloadHelper.downloadBlob(blob, "report.zip"));

      } finally {
        dispatch(showLoading(false));
      }
  }

  const downloadEstimateSheet = async () => {
    dispatch(showLoading(true));
    try {
      const url = `${AppConfig.apiUrl}excel/DownloadEstimateSheet`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${Storage.get(SecureKey.accessToken)}`
        }
      });
  
      if (response.status !== 200) {
        toast.error("download ล้มเหลว");
        return;
      }
      await response
        .blob()
        .then((blob) => DownloadHelper.downloadBlob(blob, "Program Estimate.zip"));

    } finally {
      dispatch(showLoading(false));
    }
  }

  return (
    <div style={{position: 'relative'}}>
      <MenuStepRow
        label="ข้อมูล"
        buttonStyle={buttonProps[0]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/info`}
      />
      {/* <MenuStepRow
        label="พิกัด"
        buttonStyle={buttonProps[1]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/location`}
      /> */}
      <MenuStepRow
        label="ราคาวัสดุ"
        buttonStyle={buttonProps[1]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/material`}
      />
      <MenuStepRow
        label="ปร.2"
        buttonStyle={buttonProps[2]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/pr2`}
      />
      <MenuStepRow
        label="ปร.4"
        buttonStyle={buttonProps[3]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/pr4`}
      />
      <MenuStepRow
        label="ปร.4 งานผังบริเวณ"
        buttonStyle={buttonProps[4]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/pr4/subWorkGroup/10`}
      />
      <MenuStepRow
        label="ปร.4 ค่าใช้จ่ายพิเศษ"
        buttonStyle={buttonProps[5]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/pr4/subWorkGroup/11`}
      />
      <MenuStepRow
        label="รวม ปร.4"
        buttonStyle={buttonProps[6]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/SummaryPR4`}
      />
      <MenuStepRow
        label="ปร.5"
        buttonStyle={buttonProps[7]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/pr5`}
      />
      <MenuStepRow
        label="ปร.6"
        buttonStyle={buttonProps[8]}
        href={`${AppConfig.appPath}/project/${urlParams?.projectId}/pr6`}
      />

      <MenuStepRow
        label="รวม ปร.4,5,6"
        buttonStyle={{}}
        onClick={downloadALLPR}
      />
      <MenuStepRow
        label="ประมาณราคางานเขื่อน"
        buttonStyle={{fontSize: '16px'}}
        labelS
        onClick={downloadEstimateSheet}
      />
      <div onClick={downloadEstimateSheet} style={{
          position:'absolute', zIndex:1, width: '150px', height: '20px', display: 'flex', cursor: 'pointer',
          flexDirection: 'column', alignItems: 'center', transform: 'translate(20px, -4px)'
      }}>
        <strong>Download</strong>
      </div>
      {/* <div onClick={downloadEstimateSheet} style={{
          position:'absolute', zIndex:1, width: '200px', height: '60px', display: 'flex', cursor: 'pointer',
          flexDirection: 'column', alignItems: 'center', transform: 'translate(0px, 40px)'
      }}>
        <strong>ดาวน์โหลดไฟล์</strong><span style={{fontSize: '12px', color: 'blue'}}><u>ประมาณราคางานเขื่อนป้องกันตลิ่ง</u></span>
      </div> */}
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      {/* <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}>
          <Button icon={<DownloadOutlined />}>ดาวน์โหลด ปร.4, ปร.5, ปร.6</Button>
        </Col>
      </Row> */}

      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>
      <Row style={{ height: rowHeigth }}>
        <Col xs={10} xl={21}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <VerticalLine />
        </Col>
      </Row>

      <Row style={{ height: rowHeigth }}>
        <Col xs={9} xl={18} style={{ backgroundColor: "" }}></Col>
        <Col
          xs={4}
          xl={3}
          style={{ paddingLeft: paddingLeftLine, backgroundColor: "" }}
        >
          <HomeButton />
        </Col>
      </Row>
    </div>
  );
};

export default MenuStep;
