import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import "../../../../Styles/Upload.css";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  InputNumber,
  Radio,
  Space,
  Button,
} from "antd";
import ButtonSave from "../../../../Components/ButtonSave";
import DownloadHelper from "../../../../Helpers/DownloadHelper";
import ButtonUpload from "./ButtonUpload";
import TableDocument from "./TableDocument";
import AppConfig from "../../../../AppConfig";
import UploadDocument from "./UploadDocument";

const ModalItem = ({
  formModalItem,
  isShowModalItem,
  dismissModalItem,
  modalItemData,
  customFields,
  optionFields,
  onSubmitModalItem,
  handleUpload,
  fileList,
  isDisplayUploadDocument,
  dataTableDocument,
  onDeleteRowTableDocument,
}) => {
  const radioSelectWageComponents = (option, index) => {
    return (
      <div key={`radio-${index}`}>
        <Radio value={option.value}>
          <Row>
            <Col xl={24}>
              <label className="font-common size-common">{option.name}</label>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="font-common size-common">{`ค่าแรง ${option.value}`}</label>
            </Col>
          </Row>
        </Radio>
      </div>
    );
  };

  const optionFieldsComponents = (optionInput) => {
    //longest word ปริมาณตั้งแต่ 25 - 100 ลบ.ม. หรือขุดลึก 1.00-1.50 ม.
    return (
      <div>
        {optionInput?.options?.length > 0 ? (
          <Row>
            <Col xl={24}>
              <Form.Item name="wage">
                <Radio.Group>
                  <Space direction="vertical">
                    {optionInput?.options?.map((o, index) =>
                      radioSelectWageComponents(o, index)
                    )}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  const priceAndWageInputComponent = (itemInput) => {
    return (
      <div key={`priceAndWageInput`}>
        {itemInput?.isPriceEditable ? (
          <Row>
            <Col xl={3} style={{ paddingTop: "3px" }}>
              <span className="font-common size-common">{"ราคาต่อหน่วย"}</span>
            </Col>
            <Col xl={8}>
              <Form.Item name={"priceEdit"}>
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: "100%", borderRadius: "25px" }}
                  placeholder={"ราคาต่อหน่วย"}
                  defaultValue={itemInput.priceEdit}
                ></InputNumber>
              </Form.Item>
            </Col>
          </Row>
        ) : null}
        {itemInput?.isWageEditable ? (
          <Row>
            <Col xl={3} style={{ paddingTop: "3px" }}>
              <span className="font-common size-common">{"ค่าแรง"}</span>
            </Col>
            <Col xl={8}>
              <Form.Item name={"wageEdit"}>
                <InputNumber
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  style={{ width: "100%", borderRadius: "25px" }}
                  placeholder={"ค่าแรง"}
                  defaultValue={itemInput.wageEdit}
                ></InputNumber>
              </Form.Item>
            </Col>
          </Row>
        ) : null}
      </div>
    );
  };

  const CustomFieldComponents = (field, index) => {
    return (
      <div key={field.columnRow}>
        <Row>
          <Col xl={10} style={{ paddingTop: "3px" }}>
            <span className="font-common size-common">{field.name}</span>
          </Col>
          <Col xl={8}>
            <Form.Item name={["columnRowValues", index]}>
              <InputNumber
                disabled={
                  // disableColumns.some((co) => field.columnRow.includes(co))
                  //   ? true
                  //   : false
                  field?.isDisable
                }
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%", borderRadius: "25px" }}
                placeholder={"จำนวน"}
              ></InputNumber>
            </Form.Item>
          </Col>
          <Col xl={4} style={{ paddingTop: "3px", marginLeft: "15px" }}>
            <span className="font-common size-common">{field.unit}</span>
          </Col>
        </Row>
      </div>
    );
  };

  const uploadComponent = () => {
    if (isDisplayUploadDocument) {
      return (
        <div>
          <Row>
            <Col xl={24}>
              <UploadDocument
                handleUpload={handleUpload}
                fileList={fileList}
              ></UploadDocument>
            </Col>
          </Row>
          {dataTableDocument?.length > 0 ? (
            <Row style={{ marginTop: "10px" }}>
              <TableDocument
                dataTable={dataTableDocument}
                onDeleteRow={onDeleteRowTableDocument}
              ></TableDocument>
            </Row>
          ) : null}
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Modal
      title={
        <label
          className="font-common"
          style={{ fontSize: "30px", paddingTop: "15px" }}
        >
          {modalItemData?.name}
        </label>
      }
      visible={isShowModalItem}
      onCancel={dismissModalItem}
      footer={null}
      width={1000}
    >
      <Form onFinish={onSubmitModalItem} form={formModalItem}>
        <Row>
          <Col
            xl={customFields?.length > 0 ? 10 : 3}
            style={{ paddingTop: "3px" }}
          >
            <span className="font-common size-common">จำนวน</span>
          </Col>

          <Col xl={8}>
            <Form.Item name={"amount"}>
              <InputNumber
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                style={{ width: "100%", borderRadius: "25px" }}
                placeholder={"จำนวน"}
              ></InputNumber>
            </Form.Item>
          </Col>
          <Col xl={3} style={{ paddingTop: "3px", marginLeft: "15px" }}>
            <span className="font-common size-common">
              {modalItemData?.unit}
            </span>
          </Col>
        </Row>
        {customFields.map((c, index) => CustomFieldComponents(c, index))}
        {optionFieldsComponents(optionFields)}
        {priceAndWageInputComponent(modalItemData)}
        {uploadComponent()}
        <Form.Item style={{ paddingTop: "5px", marginTop: "10px" }}>
          <ButtonSave label="บันทึก" width="100%"></ButtonSave>
        </Form.Item>
        <Form.Item name={"key"}>
          <Input hidden placeholder={"key"}></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalItem;
