import React from "react";
import "../Styles/CrossLine.css";

const CrossLine = ({ circleColor, isRemoveHorizontalLine }) => {
  const horizontalLine = () => {
    if (!isRemoveHorizontalLine) {
      return (
        <td>
          <div className="background1" style={{ backgroundColor: "" }}>
            <div className="line2"></div>
          </div>
        </td>
      );
    }
  };
  return (
    <table align="">
      <tbody>
        <tr>
          <td>
            <div className="background2" style={{ backgroundColor: "" }}>
              <div className="line1"></div>
            </div>
          </td>
          <td>
            <div className="background2" style={{ backgroundColor: "" }}>
              <div
                style={{
                  marginLeft: "-11px",
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  backgroundColor: circleColor ? circleColor : "orange",
                  // top: "50%",
                  // bottom: "50%",
                  marginTop: "24px",
                  borderStyle: "solid",
                  borderColor: "black",
                  borderWidth: "2px",
                  position: "absolute",
                }}
              ></div>
            </div>
          </td>
          {horizontalLine()}
        </tr>
      </tbody>
    </table>
  );
};

export default CrossLine;
