import React from "react";
import { Form, Col, Row, Image } from "antd";
import { toast } from "react-toastify";
// import AuthHelper from '../../Helper/AuthHelper';
import AppConfig from "../AppConfig";
import { LogoMain, LogoLogin } from "../Constants/Images";
import InputLogin from "./InputLogin";
import LabelRound from "./LabelRound";
import ButtonRound from "./ButtonRound";
import HTTP from "../https/HTTP";
import HTTPUtils from "../https/HTTPUtils";
import { useDispatch } from "react-redux";
import { showLoading } from "../Redux/LoadingReducer";
import SecureKey from "../https/SecureKey";
import Storage from "../https/Storage";
const LoginForm = ({ router }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onFinish = async (submitData) => {
    // console.log("Success:", submitData);
    dispatch(showLoading(true));
    await Login(submitData);
    // router.replace(AppConfig.appPath + "/home");
    dispatch(showLoading(false));
  };

  const Login = async (request) => {
    const http = HTTP("POST", "user/login", null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      // toast.success("สำเร็จ");
      const { accessToken, refreshToken } = response.data;
      Storage.set(SecureKey.accessToken, accessToken);
      Storage.set(SecureKey.refreshToken, refreshToken);
      //TODO remove this line after finish token branch
      window.sessionStorage.setItem("userId", response.data.id);
      router.replace(
        // AppConfig.appPath + "/project/select/category"
        AppConfig.appPath + "/home"
      );
    } else {
      //show error
      toast.error("ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง");
    }
  };

  return (
    <div
      className="center"
      style={{
        width: "100%",
        backgroundColor: "",
        height: "600px",
      }}
    >
      <Row justify="center" style={{ marginBottom: "10px" }}>
        <Col>
          <Image width={150} src={LogoMain} preview={false}></Image>
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: "20px" }}>
        <Col xs={18} xl={12}>
          <LabelRound
            label={
              "โปรแกรมประมาณราคาภาครัฐ กองมาตรฐานราคากลาง V." +
              AppConfig.appVersion
            }
            fontSize={"30px"}
            background={"linear-gradient(#adadad, #fefefe)"}
            height={"70%"}
          ></LabelRound>
        </Col>
      </Row>
      <Form onFinish={onFinish} form={form}>
        <Row justify="center" style={{ marginBottom: "20px" }}>
          <Col span={12}>
            <InputLogin label={"USER"} keyInput={"userName"} />
          </Col>
        </Row>
        <Row justify="center" style={{ marginBottom: "20px" }}>
          <Col span={12}>
            <InputLogin
              label={"PASSWORD"}
              type={"password"}
              keyInput={"password"}
            />
          </Col>
        </Row>
        <Row justify="center" style={{ marginBottom: "10px" }}>
          <ButtonRound
            label={"SIGN-IN"}
            fontSize={"30px"}
            background={"linear-gradient(#8dfa08, #0d9951)"}
            // height={"70%"}
            fontColor={"white"}
            borderColor={"white"}
            borderWidth={"4px"}
            labelPaddingTop={"15px"}
            width={"300px"}
            // form={form}
            // href={AppConfig.appPath + "/home"}
          ></ButtonRound>
        </Row>
      </Form>
      <Row justify="center">
        <ButtonRound
          label={"REGISTER"}
          fontSize={"30px"}
          background={"white"}
          // height={"70%"}
          fontColor={"#FF6E00"}
          href={AppConfig.appPath + "/user/create"}
          labelPaddingTop={"15px"}
          width={"300px"}
        ></ButtonRound>
      </Row>
      <Row justify="center" align="top">
        <Col
          xs={3}
          xl={1}
          style={{
            backgroundColor: "",
            paddingTop: "10px",
            display: "flex",
            justifyContent: "flex-end",
            alignContent: "center",
          }}
        >
          <Image width={50} src={LogoLogin} preview={false}></Image>
        </Col>
        <Col xs={18} xl={6} style={{ paddingLeft: "5px", paddingTop: "15px" }}>
          <LabelRound
            label={"กองมาตรฐานราคากลาง"}
            fontSize={"25px"}
            background={"black"}
            height={"70%"}
            fontColor={"white"}
            borderColor={"white"}
            borderWidth={"4px"}
            width={""}
          ></LabelRound>
        </Col>
      </Row>
    </div>
  );
};

export default LoginForm;
