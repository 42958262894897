// import logo from './logo.svg';
import React from "react";
import { BrowserRouter } from "react-router-dom";
// import './App.css';
import { useSelector } from "react-redux";
import GeneralRoutes from "./Routes/GeneralRoutes";
import ProjectRoutes from "./Routes/ProjectRoutes";
import UserRoutes from "./Routes/UserRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import LoadingScreen from "./Components/LoadingScreen";
import Loading from "react-fullscreen-loading";

const App = () => {
  let stateLoading = useSelector((state) => state.loading);
  return (
    <BrowserRouter>
      <div>
        {/* <h1>{stateLoading.isLoading ? "ASDFSDFSDF" : "1111111111111"}</h1> */}
        {/* <AppNavbar /> */}
        {/* {isLoading ? <LoadingScreen /> : null} */}
        {stateLoading.isLoading ? (
          <Loading loading loaderColor="#3498db" />
        ) : null}
        {/* <LoadingScreen /> */}

        <GeneralRoutes />
        <ProjectRoutes />
        <UserRoutes />
        <ToastContainer
          position="top-right"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          // closeOnClick
          // rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* Same as */}
        <ToastContainer />
        {/* <ToastContainer position="bottom-right" autoClose={4000} newestOnTop /> */}
      </div>
    </BrowserRouter>
  );
};

export default App;
