import React from "react";
import { Button } from "antd";

const ButtonCancel = ({ label, width, height, href, onClick }) => {
  return (
    <Button
      className="font-common"
      style={{
        background: "black",
        borderRadius: "50px",
        width,
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        borderColor: "white",
        // borderWidth: "4px",
      }}
      htmlType="submit"
      href={href}
      onClick={onClick}
    >
      <div style={{ paddingTop: "5px", fontSize: "20px", fontWeight: "bold" }}>
        {label}
      </div>
    </Button>
  );
};

export default ButtonCancel;
