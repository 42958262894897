import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../../../../Styles/Table.css";
import { toast } from "react-toastify";
import { Col, Row, Table, Form, Select } from "antd";
// import { DeleteOutlined } from "@ant-design/icons";
import HTTP from "../../../../https/HTTP";
import HTTPUtils from "../../../../https/HTTPUtils";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../../Redux/LoadingReducer";
import ButtonSave from "../../../../Components/ButtonSave";
import { columnSpecial } from "../../../../Constants/ColumnFormat";
import ModalItem from "./ModalItem";
import ModalNewItem from "./ModalNewItem";
import AppConfig from "../../../../AppConfig";

const TablePR4 = ({ selectedGroupProp, projectId, columnFormat }) => {
  const dispatch = useDispatch();
  const [isShowModalItem, setIsShowModalItem] = useState(false);
  const [modalItemData, setModalItemData] = useState();
  const [formModalItem] = Form.useForm();
  const [dataTable, setDataTable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);

  const [isShowModalNewItem, setIsShowModalNewItem] = useState();
  const [formModalNewItem] = Form.useForm();
  const [modalNewItemData, setModalNewItemData] = useState();
  const [deleteSpecialIds, setDeleteSpecialIds] = useState([]);

  const [customFields, setCustomFields] = useState([]);
  const [customSubmitInfo, setCustomSubmitInfo] = useState();
  const [optionFields, setOptionFields] = useState({});
  const [fileList, setFileList] = useState([]);
  const [isDisplayUploadDocument, setIsDisplayUploadDocument] = useState(false);
  const [dataTableDocument, setDataTableDocument] = useState([]);
  // const [columnTable, setColumnTable] = useState([]);
  // const [deepSearchArray, setDeepSearchArray] = useState([]);
  let deepSeachArray = [];
  useEffect(() => {
    (async () => {
      dispatch(showLoading(true));
      if (selectedGroupProp) {
        await getItems(selectedGroupProp);
      }
      dispatch(showLoading(false));
    })();
  }, [selectedGroupProp]);

  useEffect(() => {
    (async () => {})();
    if (dataTable) {
      if (dataTable.length > 0) {
        // setSelectedRowKeys(["1-1"]);
        deepSeachArray = [];
        deepSearchResultArray(dataTable, "isSelected", (k, v) => v === true);
        const rowKeys = deepSeachArray.map((d) => d.key);
        // console.log(rowKeys);
        setSelectedRowKeys(rowKeys);
      }
    }
  }, [dataTable]);

  useEffect(() => {
    if (isShowModalItem && modalItemData) {
      formModalItem.setFieldsValue({
        amount: modalItemData?.amount,
        key: modalItemData?.key,
        wage: optionFields?.defaultValue,
        unit: modalItemData?.unit,
        name: modalItemData?.name,
        priceEdit: modalItemData?.price,
        wageEdit: modalItemData?.wage,
      });
    }
  }, [isShowModalItem, modalItemData]);

  useEffect(() => {
    (async () => {
      dispatch(showLoading(true));
      if (isShowModalItem && customFields) {
        // console.log("customFields=========");
        // console.log(customFields);
        const columnRowValues = customFields.map((c) => c.value);
        formModalItem.setFieldsValue({
          columnRowValues: columnRowValues,
        });
      }
      dispatch(showLoading(false));
    })();
  }, [isShowModalItem, customFields]);

  useEffect(() => {
    if (isShowModalNewItem && modalNewItemData) {
      formModalNewItem.setFieldsValue({ key: modalNewItemData?.key });
    }
  }, [isShowModalNewItem, modalNewItemData]);

  const getItems = async (selectedGroupProp) => {
    let workGroupId = selectedGroupProp?.workGroupId;
    let subWorkGroupId = selectedGroupProp?.subWorkGroupId;
    if (!workGroupId) {
      workGroupId = 0;
    }

    if (!subWorkGroupId) {
      subWorkGroupId = 0;
    }

    if (subWorkGroupId == 0) {
      //get selected item by or get all and filter selected ??
    }

    const http = HTTP(
      "GET",
      `projectitem/project/${projectId}/workgroup/${workGroupId}/subworkgroup/${subWorkGroupId}`
    );
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      setDataTable(response.data);
    } else {
      //show error
    }
  };

  const onSelectChange = (record, selected, selectedRows) => {
    // console.log("selectRows");
    // console.log(selectedRows);
    // console.log("selected");
    // console.log(selected);
    // console.log("record");
    // console.log(record);
    //TODO: Know bug assume that we have projectItemId 1 and 2,3 is a child ถ้า uncheck 1 ออกจะลงว่า delete add [1,2,3]
    // แต่พอไปเพิ่ม 2 ก็จะลงว่า delete add [1,3] แล้วไป check 3 ก็จะเป็น [1] ซึ่งจริงๆควรจะลบ 1 ออกไปด้วยแต่ไม่เป็นไร เพราะสุดท้าย
    // เวลา get ค่าจาก api แล้วแสดงใน table มันจะแสดงว่า 1 , 2, 3 check
    if (!selected) {
      deepSeachArray = [];
      deepSearchResultArray(record, "id", (k, v) => v > 0);
      const deleteIdsConCat = [...deleteIds].concat(
        deepSeachArray
          .filter(
            (d) =>
              !isProjectSpecialItem(d.key) && !isProjectAccessoryItem(d.key)
          )
          .map((d) => d.projectItemId)
      );
      setDeleteIds(deleteIdsConCat);

      const deleteSpecialIdsConCat = [...deleteSpecialIds].concat(
        deepSeachArray
          .filter((d) => isProjectSpecialItem(d.key))
          .map((d) => d.id)
      );
      setDeleteSpecialIds(deleteSpecialIdsConCat);
      // if (record.projectItemId) {
      //   const itemIds = [...deleteIds].push(record.projectItemId);
      //   setDeleteIds(itemIds);
      // } else {
      //   //special item
      //   const itemSpecialIds = [...deleteSpecialIds].push(record.id);
      //   setDeleteSpecialIds(itemSpecialIds);
      // }
    } else {
      deepSeachArray = [];
      deepSearchResultArray(record, "id", (k, v) => v > 0);
      const unDeleteIds = deepSeachArray
        .filter(
          (d) => !isProjectSpecialItem(d.key) && !isProjectAccessoryItem(d.key)
        )
        .map((d) => d.projectItemId);
      const differenceIds = [...deleteIds].filter(
        (d) => !unDeleteIds.includes(d)
      );
      setDeleteIds(differenceIds);
      const unDeleteSpecialIds = deepSeachArray
        .filter((d) => isProjectSpecialItem(d.key))
        .map((d) => d.id);
      const differenceSpecialIds = [...deleteSpecialIds].filter(
        (d) => !unDeleteSpecialIds.includes(d)
      );
      setDeleteSpecialIds(differenceSpecialIds);
    }

    let keys = selectedRows.map((item) => item.key);
    // console.log(keys);
    setSelectedRowKeys(keys);
    setSelectedRowData(selectedRows);
    // this.setState({ selectedRowKeys });
  };

  const rowSelection = {
    selectedRowKeys,
    type: "checkbox",
    // fixed: true,
    onSelect: onSelectChange,
    // columnTitle: headerCheckbox,
    // onSelectAll: this.handleSelectAll
    onSelectAll: (selected, selectedRows, changeRows) => {
      console.log(selected, selectedRows, changeRows);
    },
  };

  let findIsSelected = (data) => {
    return data.some(function (e) {
      if (e.isSelected == true) return true;
      else if (e.children) return findIsSelected(e.children);
    });
  };

  const filterSelectedData = (dataInput) => {
    let result = [...dataInput].filter((data) => {
      let arr = [];
      arr.push(data);
      if (findIsSelected(arr)) {
        // console.log("found step1");
        let dataLV2 = data.children;
        if (dataLV2) {
          const dataLV2Static = [...dataLV2];
          for (let i = 0; i < dataLV2Static.length; i++) {
            let arrLV2 = [];
            arrLV2.push(dataLV2Static[i]);
            if (!findIsSelected(arrLV2)) {
              // console.log("remove lv2 i = ", i);
              let indexLV2 = dataLV2
                .map((d) => {
                  return d.key;
                })
                .indexOf(dataLV2Static[i].key);
              dataLV2.splice(indexLV2, 1);
              // console.log(dataLV2);
            } else {
              let dataLV3 = dataLV2Static[i].children;
              if (!dataLV3) continue;
              const dataLV3Static = [...dataLV3];
              for (let j = 0; j < dataLV3Static.length; j++) {
                let arrLV3 = [];
                arrLV3.push(dataLV3Static[j]);
                if (!findIsSelected(arrLV3)) {
                  // console.log("remove lv3 j = " + j + " i = " + i);
                  let indexLV3 = dataLV3
                    .map((d) => {
                      return d.key;
                    })
                    .indexOf(dataLV3Static[j].key);
                  dataLV3.splice(indexLV3, 1);
                  // console.log(dataLV3);
                } else {
                  let dataLV4 = dataLV3Static[j].children;
                  if (!dataLV4) continue;
                  const dataLV4Static = [...dataLV4];
                  for (let k = 0; k < dataLV4Static.length; k++) {
                    let arrLV4 = [];
                    arrLV4.push(dataLV4Static[k]);
                    if (!findIsSelected(arrLV4)) {
                      // console.log(
                      //   "remove lv4 k = " + k + " j = " + j + " i = " + i
                      // );
                      let indexLV4 = dataLV4
                        .map((d) => {
                          return d.key;
                        })
                        .indexOf(dataLV4Static[k].key);
                      dataLV4.splice(indexLV4, 1);
                    }
                  }
                }
              }
            }
          }
        }

        return data;
      } else {
        // console.log("not found");
      }
    });
    // let result1 = data3.map((data) => data.key);
    // console.log(result);
    return result;
  };

  const save = async () => {
    if (
      (!selectedRowData || selectedRowData.length === 0) &&
      (!deleteIds || deleteIds.length === 0)
    ) {
      toast.error("ไม่มีข้อมูลให้บันทึก");
      return;
    }

    dispatch(showLoading(true));
    var rowData = [...selectedRowData].filter(
      (d) => !isProjectSpecialItem(d.key) && !isProjectAccessoryItem(d.key)
    );
    let createRequest = rowData.map((d) => {
      return PrepareCreateOrUpdateRequest(d);
    });

    if (deleteIds.length > 0) {
      const deleteRemoveNull = deleteIds.filter((d) => d != null);
      const deleteRequest = { ids: deleteRemoveNull };
      // console.log(deleteRequest);
      const http = HTTP("POST", `projectitem/delete`, null, deleteRequest);
      const response = await http.execute();
      if (HTTPUtils.isSuccess(response)) {
        if (createRequest.length === 0) {
          toast.success("บันทึกรายการลบสำเร็จ");
        }
      } else {
        toast.error("บันทึกรายการลบล้มเหลว");
        dispatch(showLoading(false));
        return;
      }
    }

    if (deleteSpecialIds.length > 0) {
      const deleteRemoveNull = deleteSpecialIds.filter((d) => d != null);
      const deleteRequest = { ids: deleteRemoveNull };
      const http = HTTP(
        "POST",
        `projectSpecialItem/delete`,
        null,
        deleteRequest
      );
      const response = await http.execute();
      if (HTTPUtils.isSuccess(response)) {
        if (createRequest.length === 0) {
          toast.success("บันทึกรายการพิเศษลบสำเร็จ");
        }
      } else {
        toast.error("บันทึกรายการลบล้มเหลว");
        dispatch(showLoading(false));
        return;
      }
    }

    if (createRequest.length > 0) {
      const http = HTTP(
        "POST",
        `projectitem/createOrUpdate`,
        null,
        createRequest
      );
      const response = await http.execute();
      if (HTTPUtils.isSuccess(response)) {
        toast.success("บันทึกสำเร็จ");
      } else {
        toast.error("บันทึกล้มเหลว");
      }
    }

    setSelectedRowData([]);
    setDeleteIds([]);
    setDeleteSpecialIds([]);
    await getItems(selectedGroupProp);
    dispatch(showLoading(false));
  };

  const PrepareCreateOrUpdateRequest = (d) => {
    let itemId = GetItemIdByKey(d);
    // console.log(itemId);
    const subWorkGroupId =
      selectedGroupProp?.subWorkGroupId === 0
        ? null
        : selectedGroupProp.subWorkGroupId;
    const workGroupId =
      selectedGroupProp?.workGroupId === 0
        ? null
        : selectedGroupProp.workGroupId;

    var obj = Object.assign(
      {
        projectId: projectId,
        workGroupId: workGroupId,
        subWorkGroupId: subWorkGroupId,
        factorFTypeId: d.factorFTypeId,
        price: d.price,
        factorF: d.factorF,
        wage: d.wage,
        unit: d.unit,
        name: d.name,
        amount: d.amount,
        id: d.projectItemId,
        number: d.number,
      },
      itemId
    );

    return obj;
  };

  const GetItemIdByKey = (data) => {
    if (data.key.includes("-")) {
      let keySplit = data.key.split("-");
      if (keySplit.length === 2) {
        return { SubItemId1: data.id };
      } else if (keySplit.length === 3) {
        return { SubItemId2: data.id };
      } else if (keySplit.length === 4) {
        return { SubItemId3: data.id };
      }
    } else {
      return { itemId: data.id };
    }
  };

  const deepSearchResultArray = (object, key, predicate) => {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
      deepSeachArray.push(object);
    }

    for (let i = 0; i < Object.keys(object).length; i++) {
      let value = object[Object.keys(object)[i]];
      if (typeof value === "object" && value != null) {
        let o = deepSearchResultArray(
          object[Object.keys(object)[i]],
          key,
          predicate
        );
        if (o != null) {
          deepSeachArray.push(o);
        }
      }
    }
    return null;
  };

  const findItemByKey = (object, key, predicate) => {
    if (object.hasOwnProperty(key) && predicate(key, object[key]) === true) {
      return object;
    }

    for (let i = 0; i < Object.keys(object).length; i++) {
      let value = object[Object.keys(object)[i]];
      if (typeof value === "object" && value != null) {
        let o = findItemByKey(object[Object.keys(object)[i]], key, predicate);
        if (o != null) {
          return o;
        }
      }
    }
    return null;
  };

  const isNumeric = (str) => {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  };

  const readExcel = async (request) => {
    const http = HTTP("POST", `excel/read`, null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      // console.log(response.data);
      return response.data;
      // setDataTable(response.data);
    } else {
      //show error
      return null;
    }
  };

  const openModal = async (data) => {
    setModalItemData({
      amount: data.amount,
      key: data.key,
      isPriceEditable: data.isPriceEditable,
      isWageEditable: data.isWageEditable,
      name: data.name,
      unit: data.unit,
      price: data.price,
      wage: data.wage,
      id: data.id, // id = projectItemId or projectSpecialItemId look at data.key value
      projectItemId: data.projectItemId,
    });

    if (data.additionalContent) {
      // console.log(data.additionalContent);
      var content = JSON.parse(data.additionalContent);
      // for ระบบระบายน้ำ custom item
      if (content.fields?.length > 0) {
        if (data.isSelected) {
          const requestReadExcel = {
            projectId: projectId,
            sheetName: content.writeSheetName,
            columnRows: content.fields.map((c) => c.columnRow),
          };
          dispatch(showLoading(true));
          const responseRead = await readExcel(requestReadExcel);
          if (responseRead && responseRead.length > 0) {
            for (let i = 0; i < responseRead.length; i++) {
              content.fields[i].value = responseRead[i].value;
            }
          }
          dispatch(showLoading(false));
        }

        setCustomFields(content.fields);
        setCustomSubmitInfo({
          submitUrl: content.submitUrl,
          writeSheetName: content.writeSheetName,
          calculateSheetNames: content.calculateSheetNames,
          resultSheetName: content.resultSheetName,
          resultColumnRow: content.resultColumnRow,
        });
      }

      // for งานอาคาร which have multiple wage
      if (content.options?.length > 0) {
        const requestReadExcel = {
          projectId: projectId,
          sheetName: content.readSheetName,
          columnRows: content.options.map((c) => c.columnRow),
        };
        dispatch(showLoading(true));
        const responseRead = await readExcel(requestReadExcel);
        console.log(responseRead);
        if (responseRead && responseRead.length > 0) {
          for (let i = 0; i < responseRead.length; i++) {
            content.options[i].value = responseRead[i].value;
          }
        }
        dispatch(showLoading(false));
        setOptionFields({
          defaultValue: data.wage,
          options: content.options,
        });
      }
    }

    if (shouldDisplayUploadDocumnetPanel(data)) {
      setIsDisplayUploadDocument(true);
      getItemDocuments(data);
    } else {
      setIsDisplayUploadDocument(false);
    }

    //follow use effect show modal
    setIsShowModalItem(true);
  };

  const dismissModalItem = () => {
    formModalItem.resetFields();
    setModalItemData(null);
    setIsShowModalItem(false);
    setCustomFields([]);
    setCustomSubmitInfo({});
    setOptionFields();
    setFileList([]);
  };

  const onSubmitModalItem = async (submitData) => {
    // console.log(submitData);
    // return;
    setIsShowModalItem(false);
    dispatch(showLoading(true));
    let item = findItemByKey(dataTable, "key", (k, v) => v === submitData.key);
    if (isSubmitDataHasToReCalculate(submitData)) {
      item = await prepareItemWhichReCalculation(item, submitData);
    }

    //case อาคาร which user select price
    if (isUserSelectWage(submitData)) {
      let wage = 0;
      if (!isNumeric(submitData.wage?.toString())) {
        wage = convertToFloat(submitData.wage);
      } else {
        wage = submitData.wage;
      }

      item.wage = wage;
    }

    if (submitData.priceEdit) {
      item.price = convertToFloat(submitData.priceEdit.toString());
    }

    if (submitData.wageEdit) {
      item.wage = convertToFloat(submitData.wageEdit.toString());
    }

    item.isSelected = true;
    item.amount = submitData.amount;
    if (isProjectSpecialItem(submitData.key)) {
      updateProjectSpecialItem(item);
    } else {
      createOrUpdateItem(item);
    }

    await submitUploadDocument();

    //if dosen't delay it will not re render table after state change occur at project special item only
    setTimeout(displayCheckedItem, 500);
    formModalItem.resetFields();
    setModalItemData(null);
    setCustomFields([]);
    setCustomSubmitInfo();
    setFileList([]);
    setOptionFields();
    dispatch(showLoading(false));
  };

  const handleUpload = ({ fileList }) => {
    setFileList(fileList);
  };

  const submitUploadDocument = async () => {
    let formData = new FormData();
    if (fileList.length == 0) return;
    var fileOrigins = fileList.map((f) => f.originFileObj);
    for (let i = 0; i < fileOrigins.length; i++) {
      formData.append("fileList", fileOrigins[i]);
    }

    if (modalItemData.key.includes("special")) {
      formData.append("projectSpecialItemId", modalItemData.id);
    } else {
      formData.append("projectItemId", modalItemData.projectItemId);
    }

    formData.append("projectId", projectId);
    var url = `${AppConfig.apiUrl}projectitemdocument/upload`;
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (response.status != 200) {
      toast.error("ล้มเหลว");
      return;
    }
  };

  const shouldDisplayUploadDocumnetPanel = (data) => {
    if (
      data.isPriceEditable ||
      data.isWageEditable ||
      isProjectSpecialItem(data.key)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getItemDocuments = async (data) => {
    dispatch(showLoading(true));
    let url = "";
    if (!isProjectSpecialItem(data.key)) {
      url = `projectItemDocument/item/${data.projectItemId}`;
    } else {
      url = `projectItemDocument/specialItem/${data.id}`;
    }

    const http = HTTP("GET", url);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      setDataTableDocument(response.data);
    }
    dispatch(showLoading(false));
  };

  const onDeleteRowTableDocument = async (record) => {
    console.log("onDeleteRowTableDocument");
    dispatch(showLoading(true));
    let url = "";
    if (record.projectItemId) {
      url = `projectItemDocument/delete/item`;
    } else if (record.projectSpecialItemId) {
      url = `projectItemDocument/delete/specialItem`;
    } else {
      toast.error("ไม่พบข้อมูลการลบ");
      return;
    }

    const http = HTTP("POST", url, null, { id: record.key });
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      const dataTable = dataTableDocument.filter((dt) => dt.key != record.key);
      setDataTableDocument(dataTable);
    } else {
      toast.error("ล้มเหลว");
    }
    dispatch(showLoading(false));
  };

  const isSubmitDataHasToReCalculate = (submitData) => {
    return submitData?.columnRowValues?.length > 0;
  };

  const isUserSelectWage = (submitData) => {
    return submitData?.wage;
  };

  const prepareItemWhichReCalculation = async (item, submitData) => {
    var writeExcelRequest = {
      projectId: projectId, //props
      writeSheetName: customSubmitInfo.writeSheetName,
      calculateSheetNames: customSubmitInfo.calculateSheetNames,
      resultSheetName: customSubmitInfo.resultSheetName,
      resultColumnRow: customSubmitInfo.resultColumnRow,
      data: [],
    };
    for (var i = 0; i < submitData.columnRowValues.length; i++) {
      if (customFields[i].isDisable) {
        continue;
      }

      writeExcelRequest.data.push({
        columnRow: customFields[i].columnRow,
        value: submitData.columnRowValues[i],
      });
    }

    // console.log(writeExcelRequest);
    const httpWriteExcel = HTTP(
      "POST",
      customSubmitInfo.submitUrl,
      null,
      writeExcelRequest
    );

    const responseWriteExcel = await httpWriteExcel.execute();
    if (HTTPUtils.isSuccess(responseWriteExcel)) {
      // toast.success("บันทึกสำเร็จ");
      item.price = responseWriteExcel.data.price;
      if (item.name.includes("ลึกเฉลี่ย")) {
        item.name = item.name.replace(
          /_.*_/,
          `_${writeExcelRequest.data[0].value}_`
        );
      }

      return item;
      // console.log(responseWriteExcel);
    } else {
      toast.error("บันทึกล้มเหลว");
      return;
      //show error
    }
  };

  const isProjectSpecialItem = (key) => {
    return key.includes("special");
  };

  const isProjectAccessoryItem = (key) => {
    return key.includes("accessory");
  };

  const updateProjectSpecialItem = async (item) => {
    const request = {
      id: item.id,
      amount: item.amount,
    };
    // console.log(createRequest);
    const http = HTTP("POST", `projectspecialitem/update`, null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("บันทึกสำเร็จ");
      calculateItemPrice(item);
    } else {
      toast.error("บันทึกล้มเหลว");
      //show error
    }
  };

  const createOrUpdateItem = async (item) => {
    let createRequest = [];
    const request = PrepareCreateOrUpdateRequest(item);
    createRequest.push(request);
    // console.log(createRequest);
    const http = HTTP(
      "POST",
      `projectitem/createOrUpdate`,
      null,
      createRequest
    );
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("บันทึกสำเร็จ");
      calculateItemPrice(item);
    } else {
      toast.error("บันทึกล้มเหลว");
      //show error
    }
  };

  const openModalNewItem = async (data) => {
    // console.log("open modal");
    // console.log(data);
    setModalNewItemData({ key: data.key });
    setIsShowModalNewItem(true);
  };

  const dismissModalNewItem = () => {
    formModalNewItem.resetFields();
    setModalNewItemData(null);
    setIsShowModalNewItem(false);
  };

  const onSubmitModalNewItem = async (submitData) => {
    // console.log(dataInput);
    setIsShowModalNewItem(false);
    dispatch(showLoading(true));
    let item = findItemByKey(dataTable, "key", (k, v) => v === submitData.key);
    const request = PrepareNewItemRequest(item, submitData);
    console.log(request);
    const http = HTTP("POST", `projectspecialitem/create`, null, request);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("บันทึกสำเร็จ");
      await getItems(selectedGroupProp);
    } else {
      toast.error("บันทึกล้มเหลว");
      //show error
    }

    formModalNewItem.resetFields();
    setModalNewItemData(null);
    dispatch(showLoading(false));
  };

  const PrepareNewItemRequest = (item, submitData) => {
    let itemId = GetItemIdByKey(item);
    // console.log(itemId);
    let subWorkGroupId;
    if (item.subWorkGroupId && item.subWorkGroupId > 0) {
      subWorkGroupId = item.subWorkGroupId;
    } else {
      subWorkGroupId =
        selectedGroupProp.subWorkGroupId === 0
          ? null
          : selectedGroupProp.subWorkGroupId;
    }

    var obj = Object.assign(
      {
        projectId: projectId,
        workGroupId: selectedGroupProp.workGroupId,
        subWorkGroupId: subWorkGroupId,
        factorFTypeId: submitData.factorFTypeId,
        price: submitData.price,
        wage: submitData.wage,
        unit: submitData.unit,
        name: submitData.name,
        amount: submitData.amount,
      },
      itemId
    );

    return obj;
  };

  const calculateItemPrice = (item) => {
    let amount = 0;
    let price = 0;
    let wage = 0;
    if (!isNumeric(item.amount?.toString())) {
      amount = convertToFloat(item.amount);
    } else {
      amount = item.amount;
    }

    if (!isNumeric(item.price?.toString())) {
      price = convertToFloat(item.price);
    } else {
      price = item.price;
    }

    if (item.wage && !isNumeric(item.wage?.toString())) {
      wage = convertToFloat(item.wage);
    } else {
      wage = item.wage;
    }

    item.totalPrice = amount * price;
    item.totalWage = amount * wage;
    item.finalPrice = item.totalPrice + item.totalWage;
    if (item.subWorkGroupId == 1 || item.subWorkGroupId == 2) {
      // dam or irrigation
      if (item.factorF > 0) {
        item.finalPrice = item.finalPrice * item.factorF;
      }
    }
    item.totalWage = item.totalWage.toLocaleString();
    item.totalPrice = item.totalPrice.toLocaleString();
    item.finalPrice = item.finalPrice.toLocaleString();
  };

  const convertToFloat = (number) => {
    number = number?.replace(/\,/g, "");
    number = parseFloat(number);
    if (isNaN(number)) return 0;
    return number;
  };

  const calculateFactorFAPI = async () => {
    dispatch(showLoading(true));
    const http = HTTP("POST", `project/update/factorf`, null, {
      id: projectId,
    });
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      toast.success("บันทึกสำเร็จ");
      await getItems(selectedGroupProp);
    } else {
      toast.error("บันทึกล้มเหลว");
      //show error
    }
    dispatch(showLoading(false));
  };

  const displayCheckedItem = () => {
    if (dataTable) {
      let dataFilter = filterSelectedData(dataTable);
      // console.log("dataFilter");
      // console.log(dataFilter);
      setDataTable(dataFilter);
    }
  };

  return (
    <div>
      <Table
        title={() => (
          <div>
            <Row>
              <Col xl={4}>
                <ButtonSave
                  label={"บันทึก"}
                  onClick={() => {
                    // testDeepSearch(dataTestRemoveSelected);
                    save();
                  }}
                  width={"150px"}
                ></ButtonSave>
              </Col>
              <Col xl={4}>
                <ButtonSave
                  label={"แสดงเฉพาะตัวที่เลือก"}
                  onClick={displayCheckedItem}
                  width={"150px"}
                ></ButtonSave>
              </Col>
              <Col xl={4}>
                <ButtonSave
                  label={"แสดงรายการทั้งหมด"}
                  onClick={async () => {
                    dispatch(showLoading(true));
                    await getItems(selectedGroupProp);
                    dispatch(showLoading(false));
                  }}
                  // onClick={getItems(
                  //   selectedGroupProp
                  // )}
                  width={"150px"}
                ></ButtonSave>
              </Col>
              <Col xl={4}>
                <ButtonSave
                  label={"คำนวน factor f"}
                  onClick={calculateFactorFAPI}
                  width={"150px"}
                ></ButtonSave>
              </Col>
            </Row>
          </div>
        )}
        pagination={{
          defaultPageSize: 5000,
          // showSizeChanger: true,
          // pageSizeOptions: ["30", "50", "70", "100"],
        }}
        columns={columnFormat}
        dataSource={[...dataTable]}
        bordered
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              // console.log("record ", record);
              // console.log("rowIndex ", rowIndex);
              if (record.unit) {
                openModal(record);
              } else {
                openModalNewItem(record);
              }
            }, // click row
          };
        }}
        rowSelection={{ ...rowSelection, checkStrictly: false }}
        // rowClassName={(_r, i) => (i % 2 ? "odd" : "even")}
        expandable={{
          expandIconColumnIndex: 2,
          indentSize: 5,
        }}
        scroll={{ y: 440, x: 100 }}
        summary={() => {
          // summary={(pageData) => {
          // let totalFinalPrice = 0;
          deepSeachArray = [];
          deepSearchResultArray(dataTable, "isSelected", (k, v) => v === true);
          let totalFinalPrice = deepSeachArray.reduce(
            (accum, item) => accum + convertToFloat(item.finalPrice),
            0
          );
          totalFinalPrice = totalFinalPrice.toLocaleString();

          return (
            <>
              <Table.Summary.Row>
                {columnFormat != columnSpecial ? (
                  <Table.Summary.Cell></Table.Summary.Cell>
                ) : null}
                {columnFormat != columnSpecial ? (
                  <Table.Summary.Cell></Table.Summary.Cell>
                ) : null}
                {columnFormat != columnSpecial ? (
                  <Table.Summary.Cell></Table.Summary.Cell>
                ) : null}

                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell></Table.Summary.Cell>
                <Table.Summary.Cell align="center">รวม</Table.Summary.Cell>
                <Table.Summary.Cell align="center">
                  <label style={{ color: "red" }}>{totalFinalPrice}</label>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
      <ModalItem
        formModalItem={formModalItem}
        isShowModalItem={isShowModalItem}
        dismissModalItem={dismissModalItem}
        modalItemData={modalItemData}
        customFields={customFields}
        optionFields={optionFields}
        onSubmitModalItem={onSubmitModalItem}
        projectId={projectId}
        handleUpload={handleUpload}
        submitUploadDocument={submitUploadDocument}
        fileList={fileList}
        isDisplayUploadDocument={isDisplayUploadDocument}
        dataTableDocument={dataTableDocument}
        onDeleteRowTableDocument={onDeleteRowTableDocument}
      ></ModalItem>
      <ModalNewItem
        formModalNewItem={formModalNewItem}
        isShowModalNewItem={isShowModalNewItem}
        dismissModalNewItem={dismissModalNewItem}
        onSubmitModalNewItem={onSubmitModalNewItem}
      ></ModalNewItem>
    </div>
  );
};

export default TablePR4;
