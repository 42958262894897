import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "../../../../Styles/Table.css";
import { toast } from "react-toastify";
import { Col, Row, Input, Table, Popconfirm, Button } from "antd";
import { DeleteOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import HTTP from "../../../../https/HTTP";
import HTTPUtils from "../../../../https/HTTPUtils";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../../Redux/LoadingReducer";
import AppConfig from "../../../../AppConfig";
import DownloadHelper from "../../../../Helpers/DownloadHelper";

const TableDocument = ({ dataTable, onDeleteRow }) => {
  const column = [
    {
      title: "รายการ",
      dataIndex: "name",
      width: 800,
      align: "center",
    },
    {
      title: "",
      key: "action",
      align: "center",
      render: (text, record) => (
        <Button type="link" onClick={async () => onDownload(record)}>
          <CloudDownloadOutlined
            style={{ fontSize: "20px", color: "black" }}
          ></CloudDownloadOutlined>
        </Button>
      ),
    },
    {
      title: "",
      dataIndex: "operation",
      align: "center",
      render: (text, record) => (
        <Popconfirm
          title="ยืนยันการลบ"
          onConfirm={async () => onDeleteRow(record)}
          okText="ตกลง"
          cancelText="ยกเลิก"
        >
          <DeleteOutlined
            style={{ fontSize: "20px", color: "black" }}
          ></DeleteOutlined>
        </Popconfirm>
      ),
    },
  ];

  const downloadAPI = async (url, name) => {
    const response = await fetch(url, {
      method: "GET",
    });

    if (response.status != 200) {
      toast.error("download ล้มเหลว");
      return;
    }
    response.blob().then((blob) => DownloadHelper.downloadBlob(blob, name));
  };

  const onDownload = async (record) => {
    var url = "";
    if (record.projectItemId) {
      url = `${AppConfig.apiUrl}projectitemdocument/download/item/${record.key}`;
    } else if (record.projectSpecialItemId) {
      url = `${AppConfig.apiUrl}projectitemdocument/download/specialItem/${record.key}`;
    } else {
      toast.error("ไม่พบข้อมูลการลบ");
      return;
    }

    downloadAPI(url, record.name);
    // console.log(record);
  };

  return (
    <Table
      pagination={{
        defaultPageSize: 50,
      }}
      columns={column}
      dataSource={[...dataTable]}
      bordered
      //   onRow={(record, rowIndex) => {
      //     return {
      //       onClick: (event) => {
      //         console.log("record ", record);
      //         // console.log("rowIndex ", rowIndex);
      //         //   if (record.unit) {
      //         //     openModal(record);
      //         //   } else {
      //         //     openModalNewItem(record);
      //         //   }
      //       }, // click row
      //     };
      //   }}
    />
  );
};

export default TableDocument;
