import React from "react";
// import { toast } from 'react-toastify';
import { Button } from "antd";
// import AuthHelper from '../../Helper/AuthHelper';

const ButtonUpload = ({
  label,
  borderColor,
  background,
  fontColor,
  fontSize,
  width,
  height,
  borderWidth,
  labelPaddingTop,
  onClick,
}) => {
  return (
    <Button
      className="font-common"
      style={{
        background,
        borderRadius: "50px",
        width,
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: fontColor,
        borderColor,
        borderWidth,
      }}
      onClick={onClick}
    >
      <label
        style={{ paddingTop: labelPaddingTop, fontSize, fontWeight: "bold" }}
      >
        {label}
      </label>
    </Button>
  );
};

export default ButtonUpload;
