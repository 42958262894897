import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import AppConfig from "../../../AppConfig";

import ProjectUsers from '../../Project/Info/Components/ProjectUsers';

const LinkProject = ({ id, text }) => (
  <a style={{color: '#666666'}} href={`/project/${id}/info`}>{ text }</a>
)
const TableHome = ({ router, data }) => {
  const userId = parseInt(window.sessionStorage.getItem('userId') || 0, 10);
  const columns = [
    {
      title: "ชื่อโครงการ",
      dataIndex: "name",
      key: "name",
      width: 200,
      align: "center",
      render: (text, record) => (<LinkProject id={record.id} text={record.name} />),
    },
    {
      title: "งบประมาณ",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 80,
      align: "center",
      render: (text, record) => (<LinkProject id={record.id} text={record.name} />),
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      key: "type",
      width: 120,
      align: "center",
      render: (text, record) => (<LinkProject id={record.id} text={record.type} />),
    },
    {
      title: "วันที่ประเมิน",
      dataIndex: "date",
      key: "date",
      width: 100,
      align: "center",
      render: (text, record) => (<LinkProject id={record.id} text={record.date} />),
    },
    {
      title: "จังหวัด",
      dataIndex: "province",
      key: "province",
      width: 110,
      align: "center",
      render: (text, record) => (<LinkProject id={record.id} text={record.province} />),
    },

    {
      title: "User",
      width: 110,
      key: "id",
      align: "center",
      render: (text, record) => (
        userId === record.userId ? <ProjectUsers key={record.id} projectId={record.id} projectName={record.name} /> : <></>
      )
    },
  ];

  // const data = [];
  // for (let i = 0; i < 30; i++) {
  //   data.push({
  //     key: i,
  //     name: `ระบบแก้ปัญหาน้ำท่วมในเมือง ${i}`,
  //     cost: 32000000 + i,
  //     type: `ชลประทาน`,
  //     date: "20 May 2021",
  //     province: "มหาสารคาม",
  //   });
  // }

  return (
    <Table
      pagination={{
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
      }}
      columns={columns}
      dataSource={data}
      scroll={{ x: 900 }}
      bordered
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            console.log('event', event);
            // if (event.target.cellIndex < 5) {
            //   router.replace(AppConfig.appPath + `/project/${record.id}/info`);
            // }
          }, // click row
        };
      }}
    />
  );
};

export default TableHome;
