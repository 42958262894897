import React, { useEffect, useState } from "react";
import { Button } from "antd";

const ButtonWorkGroup = ({
  label,
  width,
  height,
  href,
  onClick,
  workgroupid,
  selectedWorkGroupSubWorGroup,
}) => {
  const [buttonStyle, setButtonStyle] = useState({
    borderColor: "orange",
    background: "black",
    color: "white",
  });

  const buttonInActive = {
    borderColor: "orange",
    background: "black",
    color: "white",
  };

  const buttonActive = {
    borderColor: "orange",
    background: "linear-gradient(#f07725, #ff4200)",
    color: "black",
  };

  useEffect(() => {
    if (
      selectedWorkGroupSubWorGroup?.workGroupId == workgroupid &&
      selectedWorkGroupSubWorGroup?.subWorkGroupId == 0
    ) {
      setButtonStyle(buttonActive);
      // console.log("true");
    } else {
      setButtonStyle(buttonInActive);
      // console.log("false");
    }
  }, [selectedWorkGroupSubWorGroup]);

  return (
    <Button
      className="font-common"
      style={{
        background: buttonStyle.background,
        borderRadius: "50px",
        // width: "200px",
        height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: buttonStyle.color,
        borderColor: buttonStyle.borderColor,
        borderWidth: "4px",
      }}
      htmlType="submit"
      href={href}
      onClick={onClick}
      workgroupid={workgroupid}
    >
      <label
        style={{ paddingTop: "10px", fontSize: "25px", fontWeight: "bold" }}
      >
        {label.length > 12 ? label.substring(0, 12) + ".." : label}
      </label>
    </Button>
  );
};

export default ButtonWorkGroup;
