import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { showLoading } from "../../../Redux/LoadingReducer";
import NavBar2 from "../../../Components/NavBar2";
import { Row, Layout, Col, Tree, Modal, Input, Form } from "antd";
import { Background } from "../../../Constants/Images";
import TablePR4 from "./Components/TablePR4";
import MenuStep from "../../../Components/MenuStep";
import { Area, Special } from "../../../Constants/SubWorkGroupId";
import { columnBuilding, columnSpecial } from "../../../Constants/ColumnFormat";
import { Irrigation, Dam } from "../../../Constants/Type";
import HTTP from "../../../https/HTTP";
import HTTPUtils from "../../../https/HTTPUtils";
// import { getType } from "@reduxjs/toolkit";
// const { TabPane } = Tabs;
const PR4Special = (props) => {
  const dispatch = useDispatch();
  const [groupData, setGroupData] = useState();
  const [labelNavBar, setLabelNavbar] = useState({});
  const [isBuildingType, setIsBuildingType] = useState(false);

  useEffect(() => {
    (async () => {})();
    // dispatch(showLoading(true));
    const projectId = props.match.params.projectId;
    const subWorkGroupId = props.match.params.subWorkGroupId;
    getProjectType(projectId);
    if (subWorkGroupId == Area) {
      setLabelNavbar({ thai: "ปร.4 งานผังบริเวณ", english: "PR4 Area" });
    } else if (subWorkGroupId == Special) {
      setLabelNavbar({
        thai: "ปร.4 ค่าใช้จ่ายพิเศษ",
        english: "PR4 Special",
      });
    }
    setGroupData({ projectId: projectId, subWorkGroupId: subWorkGroupId });
    // dispatch(showLoading(false));
  }, []);

  const getProjectType = async (projectId) => {
    // dispatch(showLoading(true));
    const http = HTTP("GET", `project/${projectId}`);
    const response = await http.execute();
    if (HTTPUtils.isSuccess(response)) {
      var typeId = response.data.typeId;
      if (typeId == Irrigation || typeId == Dam) {
        setIsBuildingType(false);
      } else {
        setIsBuildingType(true);
      }
    } else {
      toast.error("ไม่พบข้อมูล");
    }
    // dispatch(showLoading(false));
  };

  const selectedColumnFormat = () => {
    const subWorkGroupId = props.match.params.subWorkGroupId;
    if (subWorkGroupId == Special) {
      return columnSpecial;
    } else {
      return columnBuilding;
    }
  };

  return (
    <div>
      <NavBar2
        labelEnglish={labelNavBar?.english}
        labelThai={labelNavBar?.thai}
      ></NavBar2>
      <Layout>
        <Layout.Content
          className="background"
          style={{ backgroundImage: `url("${Background}")` }}
        >
          <Row>
            <Col xs={24} xl={3} style={{ background: "" }}>
              <MenuStep
                selectedIndex={
                  props.match.params.subWorkGroupId == Area ? 4 : 5
                }
                urlParams={props.match.params}
              ></MenuStep>
            </Col>
            <Col
              xs={24}
              xl={21}
              style={{ paddingLeft: "30px", paddingTop: "20px" }}
            >
              {isBuildingType ? (
                <Row>
                  <Col
                    xl={24}
                    style={{
                      backgroundColor: "",
                    }}
                  >
                    <TablePR4
                      selectedGroupProp={groupData}
                      projectId={props.match.params.projectId}
                      columnFormat={selectedColumnFormat()}
                    />
                  </Col>
                </Row>
              ) : (
                <div
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <label
                    style={{
                      marginTop: "10px",
                      fontSize: "20px",
                    }}
                    className="font-common"
                  >
                    โครงการที่เป็นระบบระบายน้ำท่วมหรือเขื่อนจะไม่มีงานปร.4
                    ผังบริเวณและค่าใช้จ่ายพิเศษ
                  </label>
                </div>
              )}
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default PR4Special;
