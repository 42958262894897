// import CryptoJS from 'crypto-js';
import React from "react";
import AppConfig from "../AppConfig";

import SecureKey from "./SecureKey";
import Storage from "./Storage";

import HTTPUtils from "./HTTPUtils";

import { toast } from "react-toastify";

export default async function RefreshToken() {
  try {
    const controller = new AbortController();
    const signal = controller.signal;

    // const requestHeaders = HTTPUtils.getHttpHeader(SecureKey.refreshToken);
    // const response = await fetch(AppConfig.apiUrl + `user/refresh`, {
    //   method: "POST",
    //   headers: requestHeaders,
    // });

    var requestHeaders = new Headers();
    const refreshToken = Storage.get(SecureKey.refreshToken);
    requestHeaders.append("Authorization", "Bearer " + refreshToken);
    requestHeaders.append("Content-Type", "application/json");
    // let body = { refreshToken: refreshToken };
    const response = await fetch(AppConfig.apiUrl + `user/refresh`, {
      method: "POST",
      headers: requestHeaders,
    });

    if (HTTPUtils.isSuccess(response)) {
      var data = await response.json();
      console.log(data);
      Storage.set(SecureKey.accessToken, data.accessToken);
      Storage.set(SecureKey.refreshToken, data.refreshToken);
    } else {
      Storage.clear(SecureKey.accessToken);
      Storage.clear(SecureKey.refreshToken);
      // Storage.clear(SecureKey.loginType);
      toast.warn("Token หมดอายุกรณี Login ใหม่อีกครั้ง");
      setTimeout(() => window.location.replace(AppConfig.appPath), 2000);
    }
  } catch (error) {
    console.log("error in API RefreshToken");
    console.log(error);
    throw error;
  }

  // console.log({ status, statusText, bsRefreshToken });
  return null;
}

// const base64url = (source) => {
// 	// Encode in classical base64
// 	encodedSource = CryptoJS.enc.Base64.stringify(source);

// 	// Remove padding equal characters
// 	encodedSource = encodedSource.replace(/=+$/, '');

// 	// Replace characters according to base64url specifications
// 	encodedSource = encodedSource.replace(/\+/g, '-');
// 	encodedSource = encodedSource.replace(/\//g, '_');

// 	return encodedSource;
// };
