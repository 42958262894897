import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";

const Map = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      `https://maps.googleapis.com/maps/api/js?key=${ process.env.REACT_APP_GOOGLE_MAP_API_KEY }&v=3.exp&libraries=geometry,drawing,places&language=th`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={7}
    onClick={props.onClick}
    center={
      props.latLong
        ? { lat: props.latLong?.lat, lng: props.latLong?.long }
        : { lat: 13.7994107, lng: 100.2862461 }
    }
  >
    {props.latLong ? (
      <div>
        <Marker
          position={{ lat: props.latLong.lat, lng: props.latLong.long }}
        />
        <Circle
          center={{ lat: props.latLong.lat, lng: props.latLong.long }}
          radius={props.radius ? 1000 * props.radius : 1000 * 100}
          onClick={props.onClick}
        />
      </div>
    ) : null}
  </GoogleMap>
));
export default Map;
